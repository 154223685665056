import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useUserRole = () => {
    const [role, setRole] = useState(null);
    const userInfo = useSelector((state) => state.user);

    useEffect(() => {
        if (userInfo?.user?.role) {
            setRole(userInfo.user.role);
        }
    }, [userInfo]);

    return role;
};

export default useUserRole;
