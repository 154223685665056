import { Message } from "./components/Message";

const MessageList = ({ messages }) => {
    return (
        <ul className="participants-list">
            {messages?.length > 0 &&
                messages?.map((message, index) => (
                    <Message
                        key={index}
                        message={message.senderId.user}
                        messageText={message.content}
                        samePrevUser={index > 0 ? message?.peerId === messages[index - 1]?.peerId : false}
                        sameNextUser={!!message?.peerId === messages[index + 1]?.peerId}
                    />
                ))}
        </ul>
    );
};

export default MessageList;
