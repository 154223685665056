import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDownloadImage } from "../../../../hooks/core/DownloadImage";

// options is object that has downloadMedia and deleteMedia properties (boolean)
const UploadImage = ({
    title,
    width = 170,
    height = 160,
    options = false,
    defaultImage = "",
    readOnly = false,
    onChangeImage,
}) => {
    const MAX_FILE_SIZE = 1024 * 1024;
    const { t } = useTranslation("common");
    const [imageUrl, setImageUrl] = useState("");
    const [isDragActive, setIsDragActive] = useState(false);

    const [handleDownload] = useDownloadImage();

    const handleAvatarUpload = (files) => {
        if (readOnly) {
            return;
        }

        const file = files?.length > 0 && files[0];

        if (
            file?.type?.includes("image/png") ||
            file?.type?.includes("image/jpg") ||
            file?.type?.includes("image/jpeg")
        ) {
            const fileSize = file?.size;

            if (fileSize < MAX_FILE_SIZE) {
                const avatarUrl = file && URL.createObjectURL(file);

                setImageUrl(avatarUrl);
                onChangeImage && onChangeImage(file);
            } else {
                alert(t("common:uploadImage.largeImageError"));
            }
        } else {
            alert(t("common:uploadImage.wrongImageFormat"));
        }
    };

    const handleDrag = (event) => {
        if (readOnly) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (event.type === "dragenter" || event.type === "dragover") {
            setIsDragActive(true);
        } else if (event.type === "dragleave") {
            setIsDragActive(false);
        }
    };

    const handleDrop = (event) => {
        if (readOnly) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        setIsDragActive(false);

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            handleAvatarUpload(event.dataTransfer.files);
        }
    };

    const handleDeleteMedia = () => {
        setImageUrl("");
        onChangeImage && onChangeImage("");
    };

    return (
        <div className="image-upload">
            {title && (
                <label htmlFor="image" className="col-form-label text-center user-modal__title">
                    {title}
                </label>
            )}
            <div style={{ position: "relative", margin: options ? "0 0 20px" : "45px 0" }} onDragEnter={handleDrag}>
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input d-none"
                        id="image"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={(event) => handleAvatarUpload(event?.target?.files)}
                    />
                    <label
                        style={{
                            width: width,
                            height: height,
                            borderStyle: (defaultImage && !imageUrl) || imageUrl ? "solid" : "dashed",
                            cursor: readOnly ? "default" : "pointer",
                        }}
                        className="custom-file-label"
                        htmlFor={readOnly ? "" : "image"}
                    >
                        {defaultImage && !imageUrl ? (
                            <img
                                className="logo"
                                src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${defaultImage}`}
                                alt=""
                            />
                        ) : imageUrl ? (
                            <img src={imageUrl} alt="avatar" />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: t("common:uploadImage.description") }}></div>
                        )}
                    </label>
                </div>
                {isDragActive && (
                    <div
                        style={{ width: width, height: height }}
                        className="custom-file--drag-drop"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    ></div>
                )}
            </div>
            <div className="image-upload__icons-container">
                {options?.downloadMedia && (
                    <div
                        className="image-upload__icon"
                        onClick={() => {
                            handleDownload(defaultImage);
                        }}
                    >
                        <i className="bi bi-download"></i> <span>{t("common:uploadImage.downloadMedia")}</span>
                    </div>
                )}
                {options?.deleteMedia && (
                    <div className="image-upload__icon" onClick={handleDeleteMedia}>
                        <i className="bi bi-trash3"></i> <span>{t("common:uploadImage.deleteMedia")}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadImage;
