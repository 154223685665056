import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./components/Header";
import { SidebarMenu } from "./components/SidebarMenu";
import { Footer } from "./components/Footer";
import { useUserToken } from "../../hooks/core/UserToken";
import { userManagement } from "../../hooks/api/UserManagement";
import { workspaceManagement } from "../../hooks/api/WorkspaceManagement";
import { setUser } from "../../slices/User";
import { setIsLoading, setRefresh } from "../../slices/Status";

const DefaultLayout = ({ children, isChatVisible, mode, title, onSidebarAction, onEndCall }) => {
    const dispatch = useDispatch();

    const [userToken] = useUserToken();
    const { useViewUserMutation } = userManagement;
    const [viewUser] = useViewUserMutation();
    const { useViewWorkspaceMutation } = workspaceManagement;
    const [viewWorkspace] = useViewWorkspaceMutation();

    const { refresh } = useSelector((state) => state.status);

    const getUserInfo = async () => {
        try {
            const decodedToken = userToken.getDecoded();
            const userId = decodedToken.id;

            dispatch(setIsLoading(true));
            const userResponse = await viewUser(userId).unwrap();

            let workspaces = userResponse.info.user?.workspaces;
            workspaces =
                workspaces.length > 0 &&
                workspaces.filter(
                    (workspace) => workspace.workspace || (!workspace.workspace && workspace.role === "super admin"),
                );

            if (workspaces) {
                let workspaceResponse;

                if (workspaces?.length === 1) {
                    const workspaceId = workspaces[0].workspace;

                    if (workspaceId) {
                        workspaceResponse = await viewWorkspace(workspaceId).unwrap();
                    }

                    const user = { ...userResponse.info.user, role: workspaces[0].role };
                    sessionStorage.setItem("workspaceId", workspaceId);
                    dispatch(setUser({ user, workspace: workspaceResponse?.info?.workspace }));
                } else if (workspaces?.length > 1 && sessionStorage.getItem("workspaceId")) {
                    const workspaceId = sessionStorage.getItem("workspaceId");

                    const selectedWorkspace = workspaces?.find((workspace) => workspace?.workspace === workspaceId);

                    if (workspaceId) {
                        workspaceResponse = await viewWorkspace(workspaceId).unwrap();
                    }

                    const user = { ...userResponse.info.user, role: selectedWorkspace.role };
                    sessionStorage.setItem("workspaceId", workspaceId);
                    dispatch(setUser({ user, workspace: workspaceResponse?.info?.workspace }));
                } else {
                    const user = userResponse.info.user;
                    dispatch(setUser({ user }));
                }
            } else {
                const user = { ...userResponse.info.user, role: "user" };
                dispatch(setUser({ user }));
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (refresh) {
            getUserInfo();
            dispatch(setRefresh(false));
        }
        // eslint-disable-next-line
    }, [refresh]);

    return (
        <div className="app">
            <Header mode={mode} title={title} />
            <SidebarMenu
                onSidebarAction={onSidebarAction}
                getUserInfo={getUserInfo}
                isChatVisible={isChatVisible}
                mode={mode}
            />
            {children}
            <Footer onEndCall={onEndCall} isChatVisible={isChatVisible} mode={mode} />
        </div>
    );
};

export default DefaultLayout;
