import { MeetingItem } from "../MeetingItem";

const REMINDER_TIME = 30 * 60 * 1000;

const MeetingList = ({ list }) => {
    const now = new Date().getTime();

    return (
        <div className="calendar__meetings">
            {list?.length > 0 &&
                list?.map((item, index) => {
                    let reminder = item.startAt >= now && item?.startAt - now <= REMINDER_TIME;

                    if (index < 3) {
                        return (
                            <MeetingItem
                                title={item?.title}
                                startAt={item?.startAt}
                                endAt={item?.endAt}
                                reminder={reminder}
                                key={index}
                            />
                        );
                    }

                    return "";
                })}
            {list?.length >= 4 && <div className="calendar__meeting__text">Click to see all</div>}
        </div>
    );
};

export default MeetingList;
