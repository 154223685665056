import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const workspaceManagement = createApi({
    reducerPath: "workspaceManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            const jwtToken = localStorage.getItem("userToken");

            if (jwtToken) {
                headers.set("authorization", `Bearer ${jwtToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        workspaceList: builder.mutation({
            query: () => ({
                url: "workspace",
                method: "GET",
            }),
        }),
        createWorkspace: builder.mutation({
            query: (body) => ({
                url: "workspace",
                method: "POST",
                body: body,
            }),
        }),
        viewWorkspace: builder.mutation({
            query: (workspaceId) => ({
                url: `workspace/${workspaceId}`,
                method: "GET",
            }),
        }),
        updateWorkspace: builder.mutation({
            query: ({ body, param }) => ({
                url: `workspace/${param}`,
                method: "PATCH",
                body: body,
            }),
        }),
        deleteWorkspace: builder.mutation({
            query: (workspaceId) => {
                return {
                    url: `workspace/${workspaceId}`,
                    method: "DELETE",
                };
            },
        }),
        inviteUser: builder.mutation({
            query: ({ body, workspaceId }) => {
                return {
                    url: `workspace/email/${workspaceId}`,
                    method: "PATCH",
                    body: body,
                };
            },
        }),
        workspaceListPagination: builder.mutation({
            query: ({ page = 1, limit = 1 }) => ({
                url: `workspace?page=${page}&limit=${limit}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useWorkspaceListMutation,
    useCreateWorkspaceMutation,
    useViewWorkspaceMutation,
    useUpdateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useInviteUserMutation,
    useWorkspaceListPaginationMutation,
} = workspaceManagement;
