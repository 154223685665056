import { useUserToken } from "../UserToken";

const useUserStatus = () => {
    let isLoggedIn = false;

    const [userToken] = useUserToken();
    const isTokenValid = userToken.isValid();

    if (isTokenValid) {
        isLoggedIn = true;
    }

    return isLoggedIn;
};

export default useUserStatus;
