import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { workspaceManagement } from "../../../../hooks/api/WorkspaceManagement";
import { paymentManagement } from "../../../../hooks/api/PaymentManagement/PaymentManagement";
import { packageManagement } from "../../../../hooks/api/PackageManagement";
import { DefaultLayout } from "../../../../layouts/Default";
import { UploadImage } from "../../../Settings/components/UploadImage";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";
import { setIsLoading, setRefresh } from "../../../../slices/Status";

const WorkspaceForm = () => {
    const mode = "profile";

    const dispatch = useDispatch();

    const { packageId } = useParams();
    const navigate = useNavigate();

    const { isLoading } = useSelector((state) => state.status);

    const [workspaceInfo, setWorkspaceInfo] = useState({
        logo: "",
        name: "",
        country: "",
        city: "",
        industry: "",
        workEmail: "",
        phone: "",
    });
    const [packageInfo, setPackageInfo] = useState(null);

    const [isVisible, setIsVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");

    const { useCreateWorkspaceMutation } = workspaceManagement;
    const [createWorkspace] = useCreateWorkspaceMutation();
    const { useCreateStripeSessionMutation } = paymentManagement;
    const [createStripeSession] = useCreateStripeSessionMutation();
    const { useViewPackageMutation } = packageManagement;
    const [viewPackage] = useViewPackageMutation();

    const handleLogoChange = (image) => {
        setWorkspaceInfo({ ...workspaceInfo, logo: image });
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event?.target;

            setWorkspaceInfo({ ...workspaceInfo, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const getPackageDetails = async (id) => {
        try {
            dispatch(setIsLoading(true));

            const response = await viewPackage(id).unwrap();
            setPackageInfo(response.info?.message?.package);

            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const handleSubmit = async (event, workspaceInfo, packageInfo) => {
        try {
            event.preventDefault();
            dispatch(setIsLoading(true));

            const formData = new FormData();

            if (workspaceInfo?.logo) {
                formData.append("logo", workspaceInfo?.logo);
            }
            formData.append("name", workspaceInfo?.name);
            formData.append("packageName", packageInfo?.name);
            formData.append("country", workspaceInfo?.country);
            formData.append("city", workspaceInfo?.city);
            formData.append("industry", workspaceInfo?.industry);
            formData.append("workEmail", workspaceInfo?.workEmail);
            formData.append("phone", workspaceInfo?.phone);

            const response = await createWorkspace(formData)?.unwrap();

            const workspaceId = response?.info?.newWorkspace?._id;
            sessionStorage.setItem("workspaceId", workspaceId);

            if (packageInfo?.name !== "FreeTrial") {
                const response = await createStripeSession({
                    workspace: workspaceInfo?.name,
                    name: packageInfo?.name,
                    price: packageInfo?.price * 100,
                })?.unwrap();

                const stripeSessionUrl = response?.info?.sessionUrl || response?.sessionUrl;
                if (stripeSessionUrl) {
                    window.location = stripeSessionUrl;
                }
                dispatch(setIsLoading(false));
            } else {
                setNotificationMessage("Workspace created successfully. Redirecting to Subscription page.");
                setIsVisible(true);
                setNotificationType("success");

                dispatch(setIsLoading(false));

                setTimeout(() => {
                    dispatch(setRefresh(true));
                    navigate("/subscription");
                }, 3 * 1000);
            }
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);

            let message =
                error?.data?.error?.responseMessage ||
                "Oops! It looks like there's a connectivity issue. Please check your internet connection and try again.";
            setNotificationMessage(message);
            setIsVisible(true);
            setNotificationType("error");
        }
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setNotificationMessage("");
        setNotificationType("");
    };

    useEffect(() => {
        if (packageId) {
            getPackageDetails(packageId);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <DefaultLayout mode={mode} title={packageInfo?.name}>
            <main className="main settings details">
                <div className="settings__container">
                    <div className="settings__title">Create your workspace</div>
                    <div className="settings__container__form-container mt-3 mt-md-4">
                        <form
                            className="settings__container__form"
                            onSubmit={(event) => handleSubmit(event, workspaceInfo, packageInfo)}
                        >
                            <UploadImage
                                width={200}
                                height={200}
                                options={{ deleteMedia: true }}
                                onChangeImage={handleLogoChange}
                            />
                            <div className="settings__container__form-inputs no-order">
                                <div className="settings__container__form-input">
                                    <label htmlFor="name">Workspace Name*</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={workspaceInfo?.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="country">Country*</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={workspaceInfo?.country}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="id">City*</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={workspaceInfo?.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="industry">Industry</label>
                                    <input
                                        type="text"
                                        id="industry"
                                        name="industry"
                                        value={workspaceInfo?.industry}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="industry">Work Email</label>
                                    <div className="form-input__container">
                                        <i className="bi bi-envelope"></i>
                                        <input
                                            type="email"
                                            id="workEmail"
                                            name="workEmail"
                                            value={workspaceInfo?.workEmail}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="industry">Work Phone</label>
                                    <div className="form-input__container">
                                        <i className="bi bi-telephone"></i>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={workspaceInfo?.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="settings__btns-container">
                                    <button type="button" className="settings-btn" onClick={() => navigate(-1)}>
                                        Back
                                    </button>
                                    <button type="submit" className="settings-btn save" disabled={isLoading}>
                                        {packageInfo?.name === "FreeTrial" ? "Start free trial" : "Proceed to payment"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
            <div className="notification-list">
                <NotificationDialog
                    type={notificationType}
                    isVisible={isVisible}
                    message={notificationMessage}
                    onClose={handleCloseModal}
                />
            </div>
        </DefaultLayout>
    );
};

export default WorkspaceForm;
