import { useEffect, useState } from "react";
import { userManagement } from "../../../../../../../../hooks/api/UserManagement";

const Participant = ({ id, type, isLight, disabled, onRemoveUser }) => {
    const [user, setUser] = useState(null);
    const { useViewUserMutation } = userManagement;
    const [viewUser] = useViewUserMutation();

    const getUserInfo = async (id) => {
        try {
            if (!id) {
                return;
            }

            const response = await viewUser(id).unwrap();
            setUser(response?.info?.user);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUserInfo(id);
        // eslint-disable-next-line
    }, [id]);

    return type === "form" ? (
        <li className="premeeting__participants-list__item">
            <div className={`participant-avatar ${isLight ? "light" : ""}`}>
                {user?.firstName?.length > 0 && user?.firstName[0]}
            </div>
            {!disabled && <i className="bi bi-x participant-remove-icon" onClick={() => onRemoveUser(user)}></i>}
        </li>
    ) : (
        <li>
            <div className="participant-avatar">
                {/* <i className="bi bi-person-fill"></i> */}
                {user?.firstName?.length > 0 && user?.firstName[0]}
            </div>
        </li>
    );
};

export default Participant;
