import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userManagement } from "../../../../hooks/api/UserManagement";
import { setIsLoading } from "../../../../slices/Status";
import { DefaultLayout } from "../../../../layouts/Default";
import { Pagination } from "../../components/Pagination";
import { Table } from "../../components/Table";
import { SearchForm } from "./components/SearchForm";
import { YesNo } from "../../../../dialogs/boxes/YesNo";

const columnDefs = [
    { type: "checkbox", width: 50 },
    {
        headerName: "Name",
        cellRender: (props) => (
            <>
                {props?.firstName} {props?.lastName}
            </>
        ),
    },
    { headerName: "Position", field: "position", textAlign: "left" },
];

const WorkspacePeople = () => {
    const mode = "workspace-people";
    const title = "Workspace";

    const { workspaceId } = useParams();

    const [users, setUsers] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { useFilterUserListMutation, useDeleteUserMutation } = userManagement;
    const [filterUserList] = useFilterUserListMutation();
    const [deleteUser] = useDeleteUserMutation();

    const handlePageChange = (page, limit) => {
        const numberOfPages = Math.ceil(totalRecords / limit);
        if (!page || page > numberOfPages) {
            return;
        }
        setCurrentPage(page);
    };

    const handleDeleteUser = async () => {
        try {
            setIsVisible(false);
            dispatch(setIsLoading(true));

            if (selectedIds?.length > 0) {
                for (let i = 0; i < selectedIds.length; i++) {
                    const userId = selectedIds[i];

                    if (!userId) {
                        return;
                    }
                    await deleteUser(userId).unwrap();
                }

                handleSearch();
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleSelectUser = useCallback(
        (user) => {
            try {
                const currentUser = selectedIds?.find((selectedId) => selectedId === user?._id);

                if (currentUser) {
                    let updateIds = selectedIds?.filter((selectedId) => selectedId !== user?._id);
                    setSelectedIds(updateIds);
                } else {
                    setSelectedIds([...selectedIds, user?._id]);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [selectedIds],
    );

    const handleSearch = async (searchKey = "", page = 1, limit = 10) => {
        try {
            if (searchKey && !searchKey?.trim()) {
                return;
            }

            dispatch(setIsLoading(true));
            if (!workspaceId) {
                return;
            }

            const response = await filterUserList({
                searchKey,
                workspaceId: workspaceId,
                page,
                limit,
            }).unwrap();

            const users = response?.records;
            setUsers(users);
            setTotalRecords(response.totalRecords);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line
    }, []);

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main settings">
                <div className="settings__container">
                    <div className="settings__title">MVSoft Services</div>
                    <div className="settings__btns-title--super">Workspaces</div>
                    <div className="settings__btns-container--super">
                        <div className="settings__super-square" onClick={() => navigate(-1)}>
                            <i className="bi bi-chevron-left"></i>
                        </div>
                        <button
                            className="settings-super-btn"
                            data-button-text="Delete"
                            onClick={() => setIsVisible(true)}
                            disabled={selectedIds?.length <= 0 ? true : false}
                        >
                            Delete
                        </button>
                    </div>
                    <SearchForm onSearch={(searchKey) => handleSearch(searchKey, currentPage, countPerPage)} />
                    <Table rowData={users} colDefs={columnDefs} onCheckboxRowSelect={handleSelectUser} />
                    <Pagination
                        onNextPage={() => handlePageChange(currentPage + 1, countPerPage)}
                        onPreviousPage={() => handlePageChange(currentPage - 1, countPerPage)}
                        currentPage={currentPage}
                        countPerPage={countPerPage}
                        totalRecords={totalRecords}
                        numberOfPages={Math.ceil(totalRecords / countPerPage)}
                    />
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
            <YesNo
                isVisible={isVisible}
                text={`Are you sure you want to delete ${selectedIds?.length === 1 ? "this user" : "these users"}?`}
                onCancel={() => setIsVisible(false)}
                onConfirm={handleDeleteUser}
            />
        </DefaultLayout>
    );
};

export default WorkspacePeople;
