import { useEffect, useState } from "react";

const UserItem = ({ user, selectedUsers, onSelectUser }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const selectedUser = selectedUsers?.find((selectedUser) => selectedUser === user.id);
        if (selectedUser) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }

        // eslint-disable-next-line
    }, [selectedUsers]);

    return (
        <div className={`confirm__list-item ${isChecked ? "selected" : ""}`}>
            <div className="confirm__list-item__container">
                <div className={`participant-avatar confirm__list-item__image ${user?.avatar ? "no-background" : ""}`}>
                    <i className="bi bi-person-fill"></i>
                    {user?.avatar && (
                        <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${user?.avatar}`} alt="avatar" />
                    )}
                </div>
                <div className="confirm__list-item__user-info">
                    {user.email}
                    <br />
                    <span>
                        {user?.firstName} {user?.lastName}
                    </span>
                </div>
            </div>
            <input type="checkbox" checked={isChecked} onChange={onSelectUser} />
        </div>
    );
};

export default UserItem;
