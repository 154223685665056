import { Form } from "../components/Form";
import { Banner } from "../components/Banner";
import packageJsonInfo from "../../../../package.json";
import { useParams } from "react-router-dom";

const Register = () => {
    const { email: emailFromUrl } = useParams();

    return (
        <main className="login">
            <div className="login__container">
                <Form
                    mode="sign-up"
                    description={
                        emailFromUrl
                            ? "You have been invited to this workspace by the administrator. Please create an account to continue."
                            : ""
                    }
                />
                <Banner />
                <div className="login__container__app-version">{packageJsonInfo?.version}</div>
            </div>
        </main>
    );
};

export default Register;
