import { useEffect } from "react";

const NotificationDialog = ({ type = "warning", isVisible, onClose, message, timer = 3, selfClosing = true }) => {
    useEffect(() => {
        let interval;

        if (isVisible && !interval && selfClosing) {
            interval = setTimeout(() => {
                onClose(isVisible);
                interval = null;
            }, timer * 1000);
        }

        return () => clearTimeout(interval);
        // eslint-disable-next-line
    }, [isVisible]);

    return (
        isVisible && (
            <div className="notification-dialog active">
                <div className="notification-dialog__body">
                    <div className="notification-dialog__icon">
                        {type === "success" ? (
                            <i className="bi bi-check2-circle text-success"></i>
                        ) : type === "error" ? (
                            <i className="bi bi-exclamation-circle text-danger"></i>
                        ) : type === "user" ? (
                            <i className="bi bi-person-bounding-box"></i>
                        ) : (
                            <i className="bi bi-exclamation-circle"></i>
                        )}
                    </div>
                    <div className="notification-dialog__text">{message}</div>
                </div>
                <div className="notification-dialog__close" onClick={() => onClose(isVisible)}>
                    <i className="bi bi-x-lg"></i>
                </div>
            </div>
        )
    );
};

export default NotificationDialog;
