import { useEffect, useState } from "react";
import { Form } from "../components/Form";
import { Banner } from "../components/Banner";
import packageJsonInfo from "../../../../package.json";

const Login = () => {
    const [view, setView] = useState("login");

    const handleView = (value) => {
        setView(value);
        localStorage.setItem("loginNumber", 1);
    };

    const handleResize = () => {
        const loginNumber = localStorage.getItem("loginNumber");
        if (window?.innerWidth < 769) {
            if (loginNumber) {
                setView("form");
            } else {
                setView("banner");
            }
        } else {
            setView("login");
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <main className="login">
            <div className="login__container">
                {view !== "banner" && <Form mode="login" />}
                {view !== "form" && <Banner isMobile={view === "banner"} handleView={handleView} />}
                <div className="login__container__app-version">{packageJsonInfo?.version}</div>
            </div>
        </main>
    );
};

export default Login;
