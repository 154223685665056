import { useEffect, useState } from "react";

const MeetingItem = ({ title, startAt, endAt, reminder }) => {
    const [time, setTime] = useState({
        startAt: "",
        endAt: "",
    });

    const formatTime = (timestamp) => {
        try {
            if (!timestamp) {
                return "";
            }

            const newDate = new Date(JSON.parse(timestamp));

            let hours = newDate.getHours() + "";
            let minutes = newDate.getMinutes() + "";

            hours = hours.padStart(2, "0");
            minutes = minutes.padStart(2, "0");

            return hours + ":" + minutes;
        } catch (error) {
            console.log(error);
            return "";
        }
    };

    const handleTime = () => {
        try {
            const startOfMeeting = formatTime(startAt);
            const endOfMeeting = formatTime(endAt);

            setTime({ startAt: startOfMeeting, endAt: endOfMeeting });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleTime();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="calendar__meeting">
            <div className="calendar__meeting__title">{title}</div>
            {reminder ? (
                <div className="calendar__meeting__duration reminder">
                    <span></span> Begins in less then 30m
                </div>
            ) : (
                <div className="calendar__meeting__duration">
                    {time.startAt} <span></span> {time.endAt}
                </div>
            )}
        </div>
    );
};

export default MeetingItem;
