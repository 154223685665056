import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../layouts/Default";
import backIcon from "../../../../assets/images/back-icon.svg";
import cardVisaIcon from "../../../../assets/images/card-visa.svg";

const WorkspaceSubscriptionDetails = () => {
    const mode = "workspace-subscription-details";
    const title = "Bank account";

    const navigate = useNavigate();

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main settings details">
                <div className="settings__container">
                    <div className="settings__header">
                        <div className="settings__title">Edit bank account</div>
                        <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                            <img src={backIcon} alt="Back icon" />
                        </div>
                    </div>

                    <form className="settings__container__form-bank">
                        <div className="settings__container__form-input align-items-start">
                            <label htmlFor="bank">Bank</label>
                            <input type="text" id="bank" name="bank" placeholder="Bank Intesa" />
                        </div>
                        <div className="settings__container__form-input align-items-start my-3">
                            <label htmlFor="ibn">IBN*</label>
                            <div className="form-input__container bank-card">
                                <img src={cardVisaIcon} alt="Card Icon" />
                                <input type="number" id="ibn" name="ibn" placeholder="1784006720056235" />
                            </div>
                        </div>
                        <div className="settings__container__form-input align-items-start">
                            <label htmlFor="swiftCode">Swift code</label>
                            <input id="swiftCode" name="swiftCode" placeholder="DGVJOYGSKS" />
                        </div>
                        <div className="settings__btn_container">
                            <button type="submit" className="settings-btn subscription">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
        </DefaultLayout>
    );
};

export default WorkspaceSubscriptionDetails;
