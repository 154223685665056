import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/AppContext";
import { useParams } from "react-router-dom";

const ParticipantsList = () => {
    const { socket } = useContext(AppContext);
    const { t } = useTranslation("common");
    const { meetingId: meetingIdFromURL } = useParams();

    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        socket.emit("users-in-room", meetingIdFromURL);

        socket.on("get-users-in-room", (participants, meetingId) => {
            if (meetingId === meetingIdFromURL) {
                setParticipants(participants);
            }
        });

        return () => {
            socket?.off("get-users-in-room");
        };
    }, []);

    return (
        <div className="main__container-welcome-text">
            <div className="main__container-welcome-p">{meetingIdFromURL}</div>
            <div className="main__container-welcome-h">{t("common:readyToJoin")}</div>
            <div className="header__content-participants">
                <div className="header__content-participants-list">
                    <div className="header__content-participants-list">
                        <ul className="premeeting__participants-list">
                            {participants?.map((participant, index) => {
                                if (index < 4) {
                                    return (
                                        <li key={index} className="header__content-participant">
                                            <div className="participant-avatar">
                                                {participant?.user?.avatar ? (
                                                    <img
                                                        src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${participant?.user?.avatar}`}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <span className="avatar-initials--large">
                                                        {participant?.user?.firstName[0]}
                                                    </span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="main__container__participants-names">
                {participants?.map((participant, index) => {
                    const sign =
                        participants?.length - index - 1 === 0
                            ? ""
                            : participants?.length - index - 1 === 1
                              ? ` ${t("common:and")} `
                              : ", ";

                    return (
                        <React.Fragment key={index}>
                            {participant?.user?.firstName}
                            {sign}
                        </React.Fragment>
                    );
                })}{" "}
                {participants?.length > 1 && t("common:inMeet")}
            </div>
        </div>
    );
};

export default ParticipantsList;
