import { useEffect, useRef, useState } from "react";
import ParticipantList from "./components/ParticipantList/ParticipantList";

const MeetingDetails = ({ meetInfo, onMeetClick, index, list, isDisabled }) => {
    const [enableScroll, setEnableScroll] = useState(false);
    const [height, setHeight] = useState("none");
    const [topDistance, setTopDistance] = useState("none");
    const [time, setTime] = useState({
        startAt: "",
        endAt: "",
        duration: "",
    });
    const ref = useRef();

    const changeFormat = (date) => {
        try {
            if (!date) {
                return;
            }

            let newDate = new Date(JSON.parse(date));
            let hours = newDate?.getHours() + "";
            let minutes = newDate?.getMinutes() + "";

            hours = hours.padStart(2, "0");
            minutes = minutes.padStart(2, "0");

            return hours + ":" + minutes;
        } catch (error) {
            console.log(error);
        }
    };

    const handleMeetDuration = (startAt, endAt) => {
        try {
            let duration = endAt - startAt;
            let hours = Math.floor(duration / 3600000);
            let minutes = duration % 3600000;

            if (minutes) {
                minutes = minutes / 60000;
            }

            return `${hours ? hours + "h " : ""}${minutes ? minutes + "min" : ""}`;
        } catch (error) {
            console.log(error);
            return 0;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (meetInfo?.startAt && meetInfo?.endAt) {
                const startAtHHMM = changeFormat(meetInfo?.startAt);
                const endAtHHMM = changeFormat(meetInfo?.endAt);
                const duration = handleMeetDuration(meetInfo.startAt, meetInfo.endAt);

                setTime({
                    startAt: startAtHHMM,
                    endAt: endAtHHMM,
                    duration,
                });

                const startTimeElement = document.body.querySelector(`[data-time='${startAtHHMM}']`);
                const endTimeElement = document.body.querySelector(`[data-time='${endAtHHMM}']`);

                const startDistanceFromTop = startTimeElement?.offsetTop;
                const endDistanceFromTop = endTimeElement?.offsetTop;

                const height = endDistanceFromTop - startDistanceFromTop;

                setHeight(height);
                setTopDistance(startDistanceFromTop + 10);

                const meetingId = sessionStorage.getItem("meetingId");
                const hasSelectedMeeting = list?.find((item) => item?._id === meetingId);
                const enableScroll = hasSelectedMeeting ? meetingId === meetInfo._id : index === 0;
                setEnableScroll(enableScroll);
            }
        }, 100);

        // eslint-disable-next-line
    }, [meetInfo]);

    useEffect(() => {
        if (enableScroll) {
            ref?.current?.scrollIntoView({ behavior: "smooth" });
            setEnableScroll(false);
        }
    }, [enableScroll]);

    return (
        height !== "none" &&
        topDistance !== "none" && (
            <div
                style={{ top: topDistance, height: height }}
                className={`calendar__box__meet-details ${isDisabled ? "disabled" : ""}`}
                onClick={() => onMeetClick(meetInfo)}
                ref={ref}
            >
                <div className="calendar__meeting__title">{meetInfo?.title}</div>
                <div className="calendar__meeting__duration">
                    {time?.startAt} <span></span> {time?.endAt}
                </div>
                <ParticipantList listIds={meetInfo?.participants} />
                <span className="calendar__meeting__duration dark">{time?.duration}</span>
            </div>
        )
    );
};

export default MeetingDetails;
