import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DefaultLayout } from "../../layouts/Default";
import LobbyItem from "./components/LobbyItem/LobbyItem";

const Lobby = () => {
    const { t } = useTranslation("common");

    const mode = "lobby";
    const title = t("common:lobby.chooseWorkspace");

    const userInfo = useSelector((state) => state.user);
    const workspaces = userInfo?.user?.workspaces;

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main lobby">
                <div className="main__container__lobby">
                    <div className="lobby__list">
                        {workspaces?.length > 0 &&
                            workspaces?.map((workspace, index) => {
                                if (workspace?.workspace) {
                                    return <LobbyItem key={index} workspaceId={workspace?.workspace} />;
                                }
                                return;
                            })}
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                {t("common:founderText")} <span>{t("common:founderName")}</span>
            </div>
        </DefaultLayout>
    );
};

export default Lobby;
