import editIcon from "../../../../assets/images/🦆 icon _edit write_.png";

const Table = ({ colDefs = [], rowData = [], mode, onCheckboxRowSelect }) => {
    return (
        <div className="settings__table-container">
            <table className={`settings__table ${mode}`}>
                <thead>
                    <tr>
                        {colDefs?.length > 0 &&
                            colDefs?.map((colDef, index) => {
                                return (
                                    <th
                                        key={index}
                                        className={`settings__table--${(colDef?.headerName && colDef?.headerName?.toLowerCase()) || colDef?.type || ""}`}
                                        style={{
                                            width: colDef?.width ? colDef.width : "auto",
                                            textAlign: colDef?.textAlign || "left",
                                        }}
                                    >
                                        {colDef?.headerIcon}
                                        {colDef?.headerName}
                                    </th>
                                );
                            })}
                    </tr>
                </thead>
                <tbody className="settings__table-body">
                    {rowData?.length > 0 &&
                        rowData?.map((currentRowData, index) => {
                            return (
                                <tr key={index}>
                                    {colDefs?.length > 0 &&
                                        colDefs?.map((colDef, index) => {
                                            let tableData = "";
                                            if (colDef?.type === "edit") {
                                                tableData = (
                                                    <div
                                                        className="settings__table__row__edit"
                                                        onClick={() => {
                                                            colDef?.onClick && colDef?.onClick(currentRowData);
                                                        }}
                                                    >
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div>
                                                );
                                            } else if (colDef?.type === "edit-dark") {
                                                tableData = (
                                                    <div
                                                        className="settings__table__select"
                                                        onClick={() => {
                                                            colDef?.onClick && colDef?.onClick(currentRowData);
                                                        }}
                                                    >
                                                        <img
                                                            src={editIcon}
                                                            alt="Edit icon"
                                                            className="settings__table--edit"
                                                        />
                                                    </div>
                                                );
                                            } else if (colDef?.type === "view-dark") {
                                                tableData = (
                                                    <div
                                                        className="settings__table__select justify-content-center"
                                                        onClick={() => {
                                                            colDef?.onClick && colDef?.onClick(currentRowData);
                                                        }}
                                                    >
                                                        <i className="bi bi-eye"></i>
                                                    </div>
                                                );
                                            } else if (colDef?.type === "checkbox") {
                                                tableData = (
                                                    <input
                                                        type="checkbox"
                                                        onClick={(event) => {
                                                            onCheckboxRowSelect && onCheckboxRowSelect(currentRowData);
                                                        }}
                                                    />
                                                );
                                            } else if (colDef?.field) {
                                                if (colDef?.type === "boolean") {
                                                    tableData = JSON.stringify(currentRowData[colDef?.field]);
                                                } else {
                                                    tableData = currentRowData[colDef?.field];
                                                }
                                            } else if (colDef?.cellRender) {
                                                tableData = colDef?.cellRender(currentRowData);
                                            }

                                            return colDef?.textAlign ? (
                                                <td
                                                    key={index - 1}
                                                    className={`settings__table--${(colDef?.headerName && colDef?.headerName?.toLowerCase()) || colDef?.type || ""}`}
                                                    style={{ textAlign: colDef?.textAlign }}
                                                >
                                                    {tableData}
                                                </td>
                                            ) : (
                                                <td
                                                    key={index - 1}
                                                    className={`settings__table--${(colDef?.headerName && colDef?.headerName?.toLowerCase()) || colDef?.type || ""}`}
                                                >
                                                    {tableData} {true}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
