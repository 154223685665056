import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notFound1 from "../../assets/images/notfound1.png";
import notFound2 from "../../assets/images/notfound2.png";
import notFound3 from "../../assets/images/notfound3.png";

const NotFound = () => {
    const { t } = useTranslation("common");

    const navigate = useNavigate();

    return (
        <main className="not-found">
            <div className="not-found__container">
                <div className="not-found__container-top">
                    <div className="not-found__container-title">
                        Ooops! <br />
                        {t("common:notFound.title")}
                    </div>
                    <div className="not-found__container-description">{t("common:notFound.description")}</div>
                    <button type="button" className="log-in-btn" onClick={() => navigate(-1)}>
                        {t("common:notFound.btnLabel")}
                    </button>
                </div>
                <div className="not-found__container-bottom">
                    <div className="not-found__container-img">
                        <img src={notFound1} alt="" className="img-fluid" />
                    </div>
                    <div className="not-found__container-bottom__info">
                        <div className="not-found__container-img">
                            <img src={notFound2} alt="" className="img-fluid" />
                        </div>
                        <div className="not-found__text">
                            {t("common:founderText")} <span>{t("common:founderName")}</span>
                        </div>
                    </div>
                    <div className="not-found__container-img">
                        <img src={notFound3} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default NotFound;
