import { jwtDecode } from "jwt-decode";

const TOKEN = "userToken";

const useUserToken = () => {
    const setItem = (token) => {
        localStorage.setItem(TOKEN, token);
    };

    const getItem = () => {
        const token = localStorage.getItem(TOKEN);

        return token;
    };

    const removeToken = () => {
        localStorage.removeItem(TOKEN);
    };

    const getDecoded = () => {
        let decodedToken = "";
        const token = getItem();

        if (token) {
            decodedToken = jwtDecode(token);
        }

        return decodedToken;
    };

    const isValid = () => {
        const currentTime = Math.floor(Date.now() / 1000);

        const token = getDecoded();
        const isTokenValid = token?.exp > currentTime;

        if (token && isTokenValid) {
            return true;
        }

        return false;
    };

    return [{ setItem, getItem, removeToken, getDecoded, isValid }];
};

export default useUserToken;
