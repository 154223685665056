import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { workspaceManagement } from "../../../../hooks/api/WorkspaceManagement";
import { setUser } from "../../../../slices/User";
import { setIsLoading } from "../../../../slices/Status";
import logo from "../../../../assets/images/qmeet_logo_1.svg";

const LobbyItem = ({ type = "list", workspaceId, onCloseDropdown, isActive, getUserInfo }) => {
    const { t } = useTranslation("common");

    const [workspaceInfo, setWorkspaceInfo] = useState(null);

    const userInfo = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { useViewWorkspaceMutation } = workspaceManagement;
    const [viewWorkspace] = useViewWorkspaceMutation();

    const getWorkspaceInfo = async () => {
        try {
            dispatch(setIsLoading(true));
            if (workspaceId) {
                const response = await viewWorkspace(workspaceId).unwrap();
                setWorkspaceInfo(response?.info?.workspace);
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleSelectWorkspace = () => {
        sessionStorage.setItem("workspaceId", workspaceInfo._id);
        const selectedWorkspace = userInfo?.user?.workspaces?.find(
            (workspace) => workspace?.workspace === workspaceInfo?._id,
        );
        dispatch(setUser({ user: { role: selectedWorkspace?.role || "user" }, workspace: workspaceInfo }));
        getUserInfo && getUserInfo();

        navigate("/");
    };

    useEffect(() => {
        getWorkspaceInfo();
        // eslint-disable-next-line
    }, []);

    return type === "list" ? (
        <div className="lobby__item">
            <div className="lobby__item__info">
                {workspaceInfo?.logo ? (
                    <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${workspaceInfo?.logo}`} alt="Logo" />
                ) : (
                    <img src={logo} alt="Default logo" />
                )}
                <span>{workspaceInfo?.name}</span>
            </div>
            <div className="lobby__item__btn-container">
                <div className="lobby__item__icon">
                    <i className="bi bi-chevron-right"></i>
                </div>
                <div className="lobby__item__btn" onClick={handleSelectWorkspace}>
                    <i className="bi bi-arrow-right"></i>
                    <span>{t("common:lobby.chooseWorkspace")}</span>
                </div>
            </div>
        </div>
    ) : (
        <li
            className={`side-menu__item ${isActive ? "active" : ""}`}
            onClick={() => {
                handleSelectWorkspace();
                onCloseDropdown();
            }}
        >
            <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${workspaceInfo?.logo}`} alt="Logo" />
            <span>{workspaceInfo?.name}</span>
        </li>
    );
};

export default LobbyItem;
