import { useRef } from "react";

const PasswordInput = ({ label, onChange, password, name }) => {
    const passwordInput = useRef();
    const showPasswordIcon = useRef();

    const showPasswordIconHandler = () => {
        if (passwordInput.current.type === "password") {
            passwordInput.current.type = "text";
            showPasswordIcon.current.innerHTML = '<i className="bi bi-eye-slash"></i>';
        } else {
            passwordInput.current.type = "password";
            showPasswordIcon.current.innerHTML = '<i className="bi bi-eye"></i>';
        }
    };

    return (
        <div className="form-group password-icon">
            <label htmlFor={name}>{label}</label>
            <input
                ref={passwordInput}
                type="password"
                id={name}
                name={name}
                value={password}
                onChange={onChange}
                required
            />
            <span ref={showPasswordIcon} className="show-password" onClick={showPasswordIconHandler}>
                <i className="bi bi-eye"></i>
            </span>
        </div>
    );
};

export default PasswordInput;
