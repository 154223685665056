import React, { useState } from "react";

const PasswordInput = ({
    name,
    label,
    value,
    onChange,
    required = false,
    className = "",
    forgotPassword,
    onForgotPassword,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className={`settings__container__form-input ms-0  position-relative ${className}`}>
            <label htmlFor={name} className="text-start">
                {label}
            </label>
            <input
                type={isVisible ? "text" : "password"}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
            />
            <span
                className={`show-password ${forgotPassword ? "distance-lg" : ""}`}
                onClick={() => setIsVisible((prevState) => !prevState)}
            >
                {isVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
            </span>
            {forgotPassword && (
                <div className="btn-forgot-password">
                    <span onClick={onForgotPassword}>Forgot password?</span>
                </div>
            )}
        </div>
    );
};

export default PasswordInput;
