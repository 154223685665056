import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const userManagement = createApi({
    reducerPath: "userManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            const jwtToken = localStorage.getItem("userToken");

            if (jwtToken) {
                headers.set("authorization", `Bearer ${jwtToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        userList: builder.mutation({
            query: () => ({
                url: "users",
                method: "GET",
            }),
        }),
        filterUserList: builder.mutation({
            query: ({ searchKey = "", workspaceId = "", page = 1, limit = 10 }) => ({
                url: `users?workspaces=${workspaceId}&page=${page}&limit=${limit}&firstName=${searchKey}&lastName=${searchKey}&position=${searchKey}`,
                method: "GET",
            }),
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: "users",
                method: "POST",
                body: body,
            }),
        }),
        viewUser: builder.mutation({
            query: (userId) => ({
                url: `users/${userId}`,
                method: "GET",
            }),
        }),
        updateUser: builder.mutation({
            query: ({ body, param }) => ({
                url: `users/${param}`,
                method: "PATCH",
                body: body,
            }),
        }),
        deleteUser: builder.mutation({
            query: (userId) => {
                return {
                    url: `users/${userId}`,
                    method: "DELETE",
                };
            },
        }),
        toggleUserActivity: builder.mutation({
            query: (userId) => {
                return {
                    url: `users/activate/${userId}`,
                    method: "PATCH",
                };
            },
        }),
        checkPassword: builder.mutation({
            query: (password) => {
                return {
                    url: `users/check-password/${password}`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useUserListMutation,
    useFilterUserListMutation,
    useCreateUserMutation,
    useViewUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useToggleUserActivityMutation,
    useCheckPasswordMutation,
} = userManagement;
