import { useEffect, useState } from "react";

const TimeInput = ({ onSelectTime, time, disabled }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [hours, setHours] = useState([]);

    const generateDayHours = () => {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                let value = (hour * 60 + minute) * 60000;
                const time = {
                    value: value,
                    display: `${hour < 10 ? "0" + hour : hour}:${minute === 0 ? "00" : minute}`,
                };

                hours.push(time);
            }
        }
        setHours(hours);
    };

    useEffect(() => {
        generateDayHours();
    }, []);

    return (
        <div className="time-picker__form__input">
            <div
                className="time-picker__form__input-text"
                onClick={() => {
                    if (!disabled) {
                        setShowDropdown((prevState) => !prevState);
                    }
                }}
            >
                {hours?.length > 0 &&
                    hours?.filter((hour) => hour.value === time || hour.display === time)?.map((hour) => hour.display)}
            </div>
            <div className={`time-picker__form__input__dropdown ${showDropdown ? "open" : ""}`}>
                {hours?.length > 0 &&
                    hours?.map((hour, index) => (
                        <div
                            className="time-picker__form__input__dropdown-item"
                            key={index}
                            onClick={() => {
                                if (!disabled) {
                                    onSelectTime(hour.value);
                                    setShowDropdown(false);
                                }
                            }}
                        >
                            {hour?.display}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default TimeInput;
