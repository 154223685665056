import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import logoTransparent from "../../../../assets/images/logo-transparent.png";
import { loginManagement } from "../../../../hooks/api/LoginManagement";
import { userManagement } from "../../../../hooks/api/UserManagement";
import { useUserToken } from "../../../../hooks/core/UserToken";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";
import { PasswordInput } from "./components/PasswordInput";
import { setUser } from "../../../../slices/User";
import { setIsLoading } from "../../../../slices/Status";

const specialCharacters = ["!", "@", "#", "$", "%", "&", "*", ":", ";", ".", "?"];

const Form = ({ mode = "login", title, description }) => {
    const [loginInfo, setLoginInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });

    const { firstName, lastName, email, password, passwordConfirm } = loginInfo;

    const [isVisible, setIsVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");
    const [selfClosing, setSelfClosing] = useState(true);
    const [rememberMe, setRememberMe] = useState(false);

    const { useLoginMutation, useSignupMutation, useForgotPasswordMutation, useResetPasswordMutation } =
        loginManagement;
    const [login] = useLoginMutation();
    const [signup] = useSignupMutation();
    const [forgotPassword] = useForgotPasswordMutation();
    const [resetPassword] = useResetPasswordMutation();

    const { useViewUserMutation } = userManagement;
    const [viewUser] = useViewUserMutation();
    const dispatch = useDispatch();

    const [userToken] = useUserToken();

    const navigate = useNavigate();
    const { userId, email: emailFromUrl } = useParams();

    const handleChange = (event) => {
        const { name, value } = event.target;

        setLoginInfo({ ...loginInfo, [name]: value });
    };

    const handleRememberMe = () => {
        if (rememberMe) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
        } else {
            localStorage.setItem("email", "");
            localStorage.setItem("password", "");
        }
    };

    const validatePassword = () => {
        if (passwordConfirm !== password) {
            setIsVisible(true);
            setNotificationMessage("Please, ensure both passwords are identical.");
            setNotificationType("warning");
            setSelfClosing(true);

            return false;
        } else if (mode === "sign-up" || mode === "reset-password") {
            if (password?.length < 8) {
                setIsVisible(true);
                setNotificationMessage("Passwords must be at least 8 characters long.");
                setNotificationType("warning");
                setSelfClosing(true);

                return false;
            }

            if (password?.includes(" ")) {
                setIsVisible(true);
                setNotificationMessage("Passwords should not contain any spaces.");
                setNotificationType("warning");
                setSelfClosing(true);

                return false;
            }

            const hasSpecialCharacters = specialCharacters?.find((character) => password?.includes(character));

            if (!hasSpecialCharacters) {
                setIsVisible(true);
                setNotificationMessage("Passwords should contain at least one special character.");
                setNotificationType("warning");
                setSelfClosing(true);

                return false;
            }
        }

        return true;
    };

    const validateForm = () => {
        if (!firstName?.trim() || !lastName?.trim() || !password.trim() || !passwordConfirm.trim()) {
            setIsVisible(true);
            setNotificationMessage("Please, fill out all fields.");
            setNotificationType("warning");
            setSelfClosing(true);

            return false;
        }

        const isPasswordValid = validatePassword();

        return isPasswordValid;
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            dispatch(setIsLoading(true));

            if (mode === "login") {
                const response = await login({ email, password }).unwrap();

                const token = response?.token;
                if (token) {
                    userToken.setItem(token);
                    handleRememberMe();

                    const decodedToken = userToken.getDecoded();
                    const userId = decodedToken.id;

                    const response = await viewUser(userId).unwrap();

                    let workspaces =
                        response.info.user?.workspaces.length > 0 &&
                        response.info.user?.workspaces.filter(
                            (workspace) =>
                                workspace.workspace || (!workspace.workspace && workspace.role === "super admin"),
                        );

                    workspaces = workspaces?.length > 0 ? workspaces : null;

                    const user = { ...response.info.user, workspaces: workspaces };
                    const hasMultipleWorkspaces = Array.isArray(workspaces) && workspaces?.length > 1;
                    dispatch(setUser(user));

                    const role = workspaces?.length === 1 && workspaces[0]?.role;

                    const redirectPath =
                        role === "super admin"
                            ? "/settings"
                            : workspaces && hasMultipleWorkspaces
                              ? "/lobby"
                              : workspaces && !hasMultipleWorkspaces
                                ? "/"
                                : "/subscription";

                    navigate(redirectPath);
                }
            } else if (mode === "sign-up") {
                const isValid = validateForm();

                if (isValid) {
                    await signup(loginInfo).unwrap();

                    handleRememberMe();
                    navigate("/sign-up/activation/0");
                }
            } else if (mode === "forgotten-password") {
                await forgotPassword({ email }).unwrap();

                setNotificationMessage(
                    "We've sent a password reset link to the email associated with your account. Please check your inbox and follow the instructions in the email to reset your password.",
                );
                setNotificationType("success");
                setIsVisible(true);
                setSelfClosing(false);
            } else if (mode === "reset-password") {
                const isPasswordValid = validatePassword();

                if (isPasswordValid) {
                    const body = {
                        password,
                        passwordConfirm,
                    };

                    await resetPassword({ body, param: userId }).unwrap();

                    setIsVisible(true);
                    setNotificationMessage("Password changed successfully.");
                    setNotificationType("success");
                    setSelfClosing(true);

                    setTimeout(() => {
                        navigate("/sign-up/login");
                    }, 3.5 * 1000);
                }
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            setIsVisible(true);
            setSelfClosing(true);

            let message =
                (mode === "forgotten-password" && "Message sent.") ||
                error?.data?.error?.responseMessage ||
                "Oops! It looks like there's a connectivity issue. Please check your internet connection and try again.";
            setNotificationType("warning");
            setNotificationMessage(message);
        }
    };

    const handleCloseModal = () => {
        setIsVisible(false);
        setNotificationMessage("");
        setNotificationType("");
        setSelfClosing(true);
    };

    useEffect(() => {
        const loadCredentials = () => {
            try {
                const savedEmail = localStorage.getItem("email");
                const savedPassword = localStorage.getItem("password");

                if (savedEmail && savedPassword) {
                    setLoginInfo({
                        email: savedEmail,
                        password: savedPassword,
                    });
                    setRememberMe(true);
                }
            } catch (error) {
                console.error(error);
            }
        };
        loadCredentials();

        if (emailFromUrl) {
            setLoginInfo({ ...loginInfo, email: emailFromUrl });
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div className="login__container-left">
            <div className="login__container-left__wrapper">
                <div className="login__container-logo">
                    <img src={logoTransparent} alt="" />
                </div>
                <div className="login__container-welcome">
                    <div className="login__container-title">
                        {title || (
                            <>
                                Welcome to the <span>QMeet.</span>
                            </>
                        )}
                    </div>
                    {description && <div className="login__container-text">{description}</div>}
                    <div className="login__container-form">
                        <div className="login-form">
                            <form onSubmit={handleSubmit}>
                                {mode === "sign-up" && (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="firstName">First Name</label>
                                            <input
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                value={firstName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                value={lastName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </>
                                )}
                                {mode !== "reset-password" && (
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            required
                                            onChange={handleChange}
                                            readOnly={!!emailFromUrl}
                                        />
                                    </div>
                                )}
                                {mode !== "forgotten-password" && (
                                    <PasswordInput
                                        label="Password"
                                        password={password}
                                        onChange={handleChange}
                                        name="password"
                                    />
                                )}
                                {(mode === "sign-up" || mode === "reset-password") && (
                                    <PasswordInput
                                        label="Confirm Password"
                                        name="passwordConfirm"
                                        password={passwordConfirm}
                                        onChange={handleChange}
                                    />
                                )}
                                <div
                                    className={`login-submit ${mode === "forgotten-password" || mode === "reset-password" ? "forgotten-password" : ""} ${mode === "forgotten-password" ? "justify-content-between gap-md-3 gap-2 flex-md-nowrap flex-wrap" : ""}`}
                                >
                                    {mode !== "forgotten-password" && mode !== "reset-password" && (
                                        <div className="form-check form-switch remember-me">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="rememberMe"
                                                name="rememberMe"
                                                checked={rememberMe}
                                                onChange={() => setRememberMe((prevState) => !prevState)}
                                            />
                                            <label className="form-check-label" htmlFor="rememberMe">
                                                Remember me
                                            </label>
                                        </div>
                                    )}
                                    {mode === "forgotten-password" && (
                                        <button className="log-in-btn light" type="button" onClick={() => navigate(-1)}>
                                            Back
                                        </button>
                                    )}
                                    <button type="submit" className="log-in-btn">
                                        {mode === "login"
                                            ? "Log In"
                                            : mode === "sign-up"
                                              ? "Sign up"
                                              : mode === "forgotten-password"
                                                ? "Send"
                                                : "Save"}
                                    </button>
                                </div>
                            </form>
                            {mode === "login" ? (
                                <div className="sign-up">
                                    New around here? <Link to="/sign-up/register">SIGN UP</Link>
                                </div>
                            ) : mode === "sign-up" ? (
                                <div className="sign-up">
                                    Own an Account? <Link to="/sign-up/login">JUMP RIGHT IN</Link>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                {mode !== "forgotten-password" && mode !== "reset-password" && (
                    <div className="forgotten-password">
                        <Link to="/sign-up/forgotten-password">Forgot password?</Link>
                    </div>
                )}
            </div>
            <div className="notification-list">
                <NotificationDialog
                    type={notificationType}
                    isVisible={isVisible}
                    message={notificationMessage}
                    onClose={handleCloseModal}
                    selfClosing={selfClosing}
                />
            </div>
        </div>
    );
};

export default Form;
