import loginImage from "../../../../assets/images/login-image.png";
import logoAndName from "../../../../assets/images/logoAndName.png";

const Banner = ({ isMobile, handleView }) => {
    return (
        <div className="login__container-right">
            <div className="login__container-img">
                <img src={logoAndName} alt="" />
            </div>
            <div className="login__container-img">
                <img src={loginImage} alt="" />
            </div>
            {isMobile && (
                <div className="text-center">
                    <div className="login__container-title">
                        Welcome to the <span>QMeet</span>
                    </div>
                    <button type="button" className="log-in-btn next" onClick={() => handleView("form")}>
                        Next
                    </button>
                </div>
            )}
            <div className="login__container-welcome">
                {/* <div className="login__container-title">Online Community For QMeet Solutions.</div> */}
                <div className="login__container-description">
                    Powered by <span>QMind Solutions</span>
                </div>
            </div>
        </div>
    );
};

export default Banner;
