import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import de from "./locales/de";

const resources = {
    de: de,
    en: en,
};

const selectedLanguage = sessionStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
    resources,
    lng: selectedLanguage,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
