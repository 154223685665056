import { Form } from "../components/Form";
import { Banner } from "../components/Banner";
import packageJsonInfo from "../../../../package.json";

const ForgottenPassword = () => {
    return (
        <main className="login">
            <div className="login__container">
                <Form
                    mode="forgotten-password"
                    title="Trouble logging in?"
                    description="Enter your email and we'll send you a link to get back into your account."
                />
                <Banner />
                <div className="login__container__app-version">{packageJsonInfo?.version}</div>
            </div>
        </main>
    );
};

export default ForgottenPassword;
