import { createSlice } from "@reduxjs/toolkit";

export const statusSlice = createSlice({
    name: "status",
    initialState: {
        isLoading: false,
        refresh: false,
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setRefresh: (state, action) => {
            state.refresh = action.payload;
        },
    },
});

export const { setIsLoading, setRefresh } = statusSlice.actions;

export default statusSlice.reducer;
