import { useEffect, useState } from "react";
import { Day } from "./components/Day";

const Week = ({ currentDate, setWeekRange, setCurrentDate, meetingList, onSelectDay }) => {
    const [weekDays, setWeekDays] = useState([]);

    const handleWeek = () => {
        const startOfWeek = new Date(currentDate);
        const dayOfWeek = startOfWeek.getDay() || 7;
        const startOfCurrentWeek = new Date(startOfWeek);
        startOfCurrentWeek.setDate(startOfWeek.getDate() - dayOfWeek + 1);

        const endOfWeek = new Date(startOfCurrentWeek);
        endOfWeek.setDate(startOfCurrentWeek.getDate() + 6);
        let isSameMonth =
            startOfCurrentWeek.toLocaleDateString("default", { month: "short" }) ===
            endOfWeek.toLocaleDateString("default", { month: "short" });

        const weekRange = `${startOfCurrentWeek.toLocaleDateString("default", { month: "short" })} ${startOfCurrentWeek.getDate()?.toString()?.padStart(2, "0")} - ${!isSameMonth ? endOfWeek.toLocaleDateString("default", { month: "short" }) : ""} ${endOfWeek.getDate()?.toString()?.padStart(2, "0")} ${endOfWeek?.getFullYear()}`;

        const weekDays = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfCurrentWeek);

            day.setDate(day.getDate() + i);
            weekDays.push(day);
        }

        setWeekRange(weekRange);
        setWeekDays(weekDays);
    };

    useEffect(() => {
        handleWeek();
        // eslint-disable-next-line
    }, [currentDate]);

    return (
        <div className="calendar__box">
            <div className="calendar__box-items__wrapper">
                {weekDays?.length > 0 &&
                    weekDays?.map((day) => {
                        let isToday = false;
                        const today = new Date();
                        isToday =
                            today?.getDate() === day?.getDate() &&
                            today?.getMonth() === day?.getMonth() &&
                            today?.getFullYear() === day?.getFullYear();

                        const isSelected =
                            currentDate?.getDate() === day?.getDate() &&
                            currentDate?.getMonth() === day?.getMonth() &&
                            currentDate?.getFullYear() === day?.getFullYear();

                        return (
                            <Day
                                isToday={isToday}
                                isSelected={isSelected}
                                day={day}
                                setCurrentDate={setCurrentDate}
                                key={day?.getTime()}
                                meetingList={meetingList}
                                onSelectDay={onSelectDay}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default Week;
