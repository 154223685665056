import React from "react";

const Pagination = ({ onNextPage, onPreviousPage, currentPage, countPerPage, totalRecords, numberOfPages }) => {
    return (
        <div className="settings__table-pagination">
            <div className="pagination__count-per-page">
                Items per page: <span>{countPerPage}</span>
            </div>
            <div className="pagination__pages-container">
                <div className="pagination__pages-number">
                    Page: <span>{currentPage}</span> of {numberOfPages}
                </div>
                <div className="pagination__pages-btns">
                    <i
                        className="bi bi-chevron-left"
                        aria-disabled={true}
                        onClick={onPreviousPage}
                        style={{ cursor: currentPage === 1 ? "default" : "pointer" }}
                    ></i>
                    <i
                        className="bi bi-chevron-right"
                        onClick={onNextPage}
                        style={{ cursor: currentPage === numberOfPages ? "default" : "pointer" }}
                    ></i>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
