import { Participant } from "../Participant";

const ParticipantList = ({ listIds, type, onRemoveUser, disabled }) => {
    return (
        <ul className="premeeting__participants-list">
            {listIds?.map((participantId, index) => (
                <Participant
                    id={participantId}
                    key={index}
                    onRemoveUser={onRemoveUser}
                    type={type}
                    isLight={index % 2 === 0}
                    disabled={disabled}
                />
            ))}
        </ul>
    );
};

export default ParticipantList;
