import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DefaultLayout } from "../../layouts/Default";
import { ConferenceVideoPanel } from "./components/ConferenceVideoPanel";
import { ChatContainer } from "./components/ChatContainer";
import { Ok } from "../../dialogs/boxes/Ok";
import { meetingManagement } from "../../hooks/api/MeetingManagement";
import { setIsLoading } from "../../slices/Status";
import { AppContext } from "../../context/AppContext";

const Meeting = () => {
    const mode = "meeting";

    const { meetingId } = useParams();
    const videoPanelRef = useRef();

    const { socket } = useContext(AppContext);

    const dispatch = useDispatch();

    const { useViewMeetingMutation } = meetingManagement;
    const [viewMeeting] = useViewMeetingMutation();

    const [isChatVisible, setIsChatVisible] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState("");
    const [isMeetValid, setIsMeetValid] = useState(false);

    const handleSidebarActions = (type) => {
        switch (type) {
            case "toggle-chat":
                setIsChatVisible((prevValue) => !prevValue);
                break;

            default:
                break;
        }
    };

    const getMeetingDetails = async () => {
        try {
            if (!meetingId) {
                setIsMeetValid(false);
                return;
            }
            dispatch(setIsLoading(true));

            const response = await viewMeeting(meetingId).unwrap();

            const meetingData = response?.info?.message?.meeting;
            const startAt = meetingData?.startAt;
            const endAt = meetingData?.endAt;
            const now = new Date().getTime();

            if ((startAt <= now && endAt >= now) || (!startAt && !endAt)) {
                setIsMeetValid(true);
            } else {
                setIsMeetValid(false);
                setModalText("This meet has't started yet. Please, try again in schedule time.");
                setIsModalVisible(true);
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            // navigate("/404");
            setIsMeetValid(false);
            setModalText("Oops something went wrong. Please, try again later. Thank you.");
            setIsModalVisible(true);
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const handleEndCall = () => {
        try {
            if (videoPanelRef.current) {
                videoPanelRef.current.endCall();
            }
        } catch (error) {}
    };

    useEffect(() => {
        getMeetingDetails();
        // eslint-disable-next-line
    }, []);

    return (
        !!socket?.connected && (
            <DefaultLayout
                isChatVisible={isChatVisible}
                onSidebarAction={handleSidebarActions}
                mode={mode}
                onEndCall={handleEndCall}
            >
                <ConferenceVideoPanel isChatVisible={isChatVisible} ref={videoPanelRef} />
                <ChatContainer isChatVisible={isChatVisible} />
                <Ok isVisible={isModalVisible} title="Meeting" description={modalText} disableClose={true} />
            </DefaultLayout>
        )
    );
};

export default Meeting;
