import { Form } from "../components/Form";
import { Banner } from "../components/Banner";
import packageJsonInfo from "../../../../package.json";

const ResetPassword = () => {
    return (
        <main className="login">
            <div className="login__container">
                <Form
                    mode="reset-password"
                    title="Reset Password"
                    description="Please enter and verify a new password for your account"
                />
                <Banner />
                <div className="login__container__app-version">{packageJsonInfo?.version}</div>
            </div>
        </main>
    );
};

export default ResetPassword;
