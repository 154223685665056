import { useEffect, useState } from "react";
import { packageManagement } from "../../../../hooks/api/PackageManagement";
import { PackageItem } from "../PackageItem";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../../slices/Status";

const PackageList = () => {
    const [list, setList] = useState([]);

    const { usePackageListMutation } = packageManagement;
    const [packageList] = usePackageListMutation();

    const dispatch = useDispatch();

    const getPackageList = async () => {
        try {
            dispatch(setIsLoading(true));
            const response = await packageList().unwrap();

            setList(response.records);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        getPackageList();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="settings__cards-container">
            {list?.length > 0 &&
                list?.map((item, index) => {
                    if (item.name === "Starter") {
                        const freeTrialPackage = list?.find((packageItem) => packageItem.name === "FreeTrial");
                        return (
                            <PackageItem item={item} freeTrialPackage={freeTrialPackage} index={index} key={index} />
                        );
                    } else if (item.name !== "FreeTrial") {
                        return <PackageItem item={item} index={index} key={index} />;
                    }

                    return;
                })}
        </div>
    );
};

export default PackageList;
