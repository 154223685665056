import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userManagement } from "../../../../../../hooks/api/UserManagement";
import { setIsLoading } from "../../../../../../slices/Status";
import { Overlay } from "../../../../../../components/Overlay";
import { UserList } from "./components/UserList";

const AddPeople = ({
    isVisible = true,
    maxWidth,
    title,
    preSelectedUsers = [],
    onCancel,
    onConfirm,
    onSelectGuestes,
}) => {
    const [users, setUsers] = useState([]);
    const [type, setType] = useState("workspace");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [guest, setGuest] = useState({
        email: "",
        firstName: "",
        lastName: "",
    });
    const [errors, setErrors] = useState([]);

    const dispatch = useDispatch();
    const workspaceInfo = useSelector((state) => state.user.workspace);

    const { useFilterUserListMutation, useCreateUserMutation } = userManagement;
    const [filterUserList] = useFilterUserListMutation();
    const [createUser] = useCreateUserMutation();

    const validateInput = (name, value) => {
        try {
            const hasAlready = errors?.find((errorName) => errorName === name);
            if (!value?.trim()) {
                if (!hasAlready) {
                    setErrors([...errors, name]);
                }
            } else {
                if (hasAlready) {
                    const updatedErros = errors?.filter((errorName) => errorName !== name);
                    setErrors(updatedErros);
                }
            }
        } catch (error) {}
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event.target;
            validateInput(name, value);
            setGuest({ ...guest, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = () => {
        try {
            setType("workspace");
            setSelectedUsers([]);
            onCancel();
        } catch (error) {}
    };

    const getUsers = async () => {
        try {
            dispatch(setIsLoading(true));

            const response = await filterUserList({ workspaceId: workspaceInfo?._id }).unwrap();
            setUsers(response?.records);

            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const handleSelectUsers = (newUser) => {
        try {
            const oldUser = selectedUsers?.find((user) => user === newUser.id);
            if (oldUser) {
                const updatedUsers = selectedUsers?.filter((user) => user !== newUser.id);
                setSelectedUsers(updatedUsers);
            } else {
                setSelectedUsers([...selectedUsers, newUser.id]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validateForm = () => {
        try {
            let errorList = [];

            if (!guest.firstName?.trim()) {
                errorList = ["firstName"];
            }

            if (!guest.lastName?.trim()) {
                errorList = [...errorList, "lastName"];
            }

            if (!guest.email?.trim()) {
                errorList = [...errorList, "email"];
            }

            if (errorList?.length > 0) {
                setErrors(errorList);
                return false;
            }

            setErrors([]);
            return true;
        } catch (error) {}
    };

    const handleSubmit = async () => {
        try {
            if (type === "guest") {
                const isFormValid = validateForm();

                if (!isFormValid) {
                    return;
                }

                const response = await createUser({
                    email: guest.email,
                    password: "",
                    passwordConfirm: "",
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    guest: true,
                }).unwrap();

                const guestId = response?.info?.newUser?._id;

                onSelectGuestes({ id: guestId, email: guest.email });
                onConfirm([...selectedUsers, guestId]);
            } else {
                onConfirm(selectedUsers);
            }
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSelectedUsers(preSelectedUsers);
        // eslint-disable-next-line
    }, [preSelectedUsers]);

    return (
        <Overlay isVisible={isVisible}>
            <div
                className={`confirm-modal ${isVisible ? "open" : ""} ok add-people`}
                style={{ maxWidth: maxWidth, borderRadius: 20 }}
            >
                <div className="confirm__content-top">
                    <div className="confirm__content-top__title">{title}</div>
                    <div className="confirm__content-top__icon" onClick={onCancel}>
                        <i className="bi bi-x-lg"></i>
                    </div>
                </div>
                <div className="confirm__tabs">
                    <div
                        className={`confirm__tab ${type === "workspace" ? "active" : ""}`}
                        onClick={() => setType("workspace")}
                    >
                        Workspace
                    </div>
                    <div
                        className={`confirm__tab ${type === "guest" ? "active" : ""}`}
                        onClick={() => setType("guest")}
                    >
                        Guest
                    </div>
                </div>
                <div className="confirm__list">
                    {type === "guest" ? (
                        <div className="confirm__list-item no-hover d-block">
                            <div>
                                <p className="confirm__description">
                                    Please enter the <strong>email</strong> and <strong>full name</strong> of the user
                                    you wish to invite.
                                </p>
                                <div className="settings__container__form-input mb-2">
                                    <div className="form-input__container">
                                        <i className="bi bi-person"></i>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={guest.firstName}
                                            onChange={handleChange}
                                            placeholder="John"
                                        />
                                    </div>
                                    {errors?.includes("firstName") && (
                                        <div className="error-message text-danger">
                                            <i className="bi bi-exclamation-circle text-danger"></i> This field is
                                            required
                                        </div>
                                    )}
                                </div>
                                <div className="settings__container__form-input mb-2">
                                    <div className="form-input__container">
                                        <i className="bi bi-person"></i>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={guest.lastName}
                                            onChange={handleChange}
                                            placeholder="Doe"
                                        />
                                    </div>
                                    {errors?.includes("lastName") && (
                                        <div className="error-message text-danger">
                                            <i className="bi bi-exclamation-circle text-danger"></i> This field is
                                            required
                                        </div>
                                    )}
                                </div>
                                <div className="settings__container__form-input full-width">
                                    <div className="form-input__container">
                                        <i className="bi bi-envelope"></i>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={guest.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors?.includes("email") && (
                                        <div className="error-message text-danger">
                                            <i className="bi bi-exclamation-circle text-danger"></i> This field is
                                            required
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <UserList users={users} selectedUsers={selectedUsers} onSelectUser={handleSelectUsers} />
                    )}
                </div>
                <div className="confirm__btns">
                    <button onClick={handleClose}>Cancel</button>
                    <button className="confirm" onClick={handleSubmit}>
                        Add
                    </button>
                </div>
            </div>
        </Overlay>
    );
};

export default AddPeople;
