import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DefaultLayout } from "../../../layouts/Default";
import { loginManagement } from "../../../hooks/api/LoginManagement";
import { useUserToken } from "../../../hooks/core/UserToken";
import { setIsLoading } from "../../../slices/Status";

import successIcon from "../../../assets/images/activation-success.svg";
import failedIcon from "../../../assets/images/activation-failed.svg";
import notificationIcon from "../../../assets/images/notification.svg";

const Activation = () => {
    const mode = "lobby";

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { emailToken } = useParams();

    const [isVerified, setIsVerified] = useState(false);

    const { useVerifyAccountMutation } = loginManagement;
    const [verifyAccount] = useVerifyAccountMutation();

    const [userToken] = useUserToken();

    const handleVerifyAccount = async () => {
        try {
            dispatch(setIsLoading(true));

            const oldToken = userToken.getDecoded();

            if (oldToken) {
                userToken.removeToken();
            }

            const response = await verifyAccount(emailToken).unwrap();
            const token = response?.data?.info?.token;

            if (token) {
                userToken.setItem(token);
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (emailToken === "0") {
            setIsVerified(false);
        } else if (emailToken) {
            handleVerifyAccount();
            setIsVerified(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <DefaultLayout mode={mode} title="">
            <main className="main lobby activation">
                <div className="main__activation">
                    <div className="main__activation__cotainer">
                        <div className="main__activation__img">
                            {isVerified ? (
                                <img src={successIcon} alt="Successfull activation" />
                            ) : (
                                <>
                                    {emailToken === "0" ? (
                                        <img src={notificationIcon} alt="Failed activation" />
                                    ) : (
                                        <img src={failedIcon} alt="Failed activation" />
                                    )}
                                </>
                            )}
                        </div>
                        {isVerified ? (
                            <div className="main__activation__title">
                                Your profile is successfully <span>activated!</span>
                            </div>
                        ) : (
                            <div className="main__activation__title">
                                {emailToken === "0" ? (
                                    <>
                                        Please <span>verify your email</span> to stay connected.
                                    </>
                                ) : (
                                    <>
                                        The activation link <span>is no longer valid.</span>
                                    </>
                                )}
                            </div>
                        )}
                        {isVerified ? (
                            <div className="main__activation__description">
                                Continue to the <span>QMeet app</span> here.
                            </div>
                        ) : (
                            <div className="main__activation__description">
                                {emailToken === "0" ? (
                                    <>
                                        Check <span>your email</span> for an activation link.
                                    </>
                                ) : (
                                    <>
                                        Please register <span>again</span> here.
                                    </>
                                )}
                            </div>
                        )}
                        {isVerified ? (
                            <button className="activation-btn" onClick={() => navigate("/sign-up/login")}>
                                Login
                            </button>
                        ) : (
                            <button className="activation-btn" onClick={() => navigate("/sign-up/register")}>
                                {emailToken === "0" ? "Go back" : "Sign up"}
                            </button>
                        )}
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
        </DefaultLayout>
    );
};

export default Activation;
