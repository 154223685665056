import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const MeetingItem = ({ item }) => {
    const navigate = useNavigate();

    const now = new Date().getTime();

    const [time, setTime] = useState({
        startAt: "",
        endAt: "",
    });

    const formatTime = (time) => {
        try {
            if (!time) {
                return "";
            }

            const newTime = new Date(JSON.parse(time));

            let hours = newTime.getHours() + "";
            let minutes = newTime.getMinutes() + "";

            hours = hours.padStart(2, "0");
            minutes = minutes.padStart(2, "0");

            return hours + ":" + minutes;
        } catch (error) {
            console.log(error);

            return "";
        }
    };

    const handleMeetTime = (startAt, endAt) => {
        try {
            const startOfMeet = formatTime(startAt);
            const endOfMeet = formatTime(endAt);

            setTime({ startAt: startOfMeet, endAt: endOfMeet });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelect = (id) => {
        try {
            sessionStorage.setItem("meetingId", id);
            navigate("/calendar");
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (item) {
            handleMeetTime(item?.startAt, item?.endAt);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="home-calendar__meeting" onClick={() => handleSelect(item._id)}>
                <div className="home-calendar__meeting__duration">
                    {time.startAt} - {time.endAt}
                </div>
                <div className="home-calendar__meeting__title">{item.title}</div>
            </div>
            {now >= item.startAt && now <= item.endAt && (
                <button
                    type="button"
                    className="main__container__form__btn"
                    onClick={() => navigate(`/premeeting/${item?._id}`)}
                >
                    Join
                </button>
            )}
        </div>
    );
};

export default MeetingItem;
