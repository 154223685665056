import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../../../layouts/Default";

const WorkspaceSubscription = () => {
    const mode = "workspace-subscription";
    const title = "Bank account";

    const navigate = useNavigate();

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main settings details">
                <div className="settings__container">
                    <div className="settings__btns-title--super">Details</div>

                    <div className="settings__btns-container--super">
                        <div className="settings__btns--super">
                            <div className="settings__super-square" onClick={() => navigate("/settings")}>
                                <i className="bi bi-chevron-left"></i>
                            </div>
                            <button
                                className="settings-super-btn"
                                onClick={() => navigate("/settings/workspace-details")}
                            >
                                Details
                            </button>
                            <button
                                className="settings-super-btn"
                                onClick={() => navigate("/settings/workspace-people")}
                            >
                                People
                            </button>
                            <button
                                className="settings-super-btn active"
                                onClick={() => navigate("/settings/workspace-subscription")}
                            >
                                Subscription
                            </button>
                        </div>
                        <button className="settings-super-btn save">
                            <i className="bi bi-floppy"></i> Save
                        </button>
                    </div>

                    <div className="settings__container__form-input align-items-start">
                        <label htmlFor="subscription">Subscription</label>
                        <select
                            id="subscription"
                            name="subscription"
                            className="settings__container__form-input--select"
                        >
                            <option>Starter</option>
                            <option>Business</option>
                            <option>Enterprise</option>
                        </select>
                    </div>

                    <div className="settings__container__form-subtitle active">Bank accounts</div>
                    <div className="settings__container__cards">
                        <div className="settings__container__card">
                            <div className="settings__container__card-top">
                                <div className="settings__container__form-subtitle active">Bank Intesa</div>
                                <div className="settings__container__card__icons">
                                    <div
                                        className="settings__container__card__icon"
                                        onClick={() => navigate("/settings/workspace-subscription-details")}
                                    >
                                        <i className="bi bi-pencil-square"></i>
                                    </div>
                                    <div className="settings__container__card__icon">
                                        <i className="bi bi-trash3"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="settings__container__card-bottom">
                                <div className="settings__container__card-number">120869264904601</div>
                                <div className="settings__container__card-name">JNCKDUS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
        </DefaultLayout>
    );
};

export default WorkspaceSubscription;
