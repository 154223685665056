import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DefaultLayout } from "../../../../layouts/Default";
import { UploadImage } from "../../components/UploadImage";
import { userManagement } from "../../../../hooks/api/UserManagement";
import { workspaceManagement } from "../../../../hooks/api/WorkspaceManagement";
import { packageManagement } from "../../../../hooks/api/PackageManagement";
import { setIsLoading } from "../../../../slices/Status";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";

const WorkspaceDetails = () => {
    const mode = "workspace-details";
    const title = "Workspaces";

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { workspaceId: workspaceIdFromURL } = useParams();

    const [workspaceInfo, setWorkspaceInfo] = useState({
        logo: "",
        name: "",
        packageName: "FreeTrial",
        admin: "",
        country: "",
        city: "",
        workEmail: "",
        industry: "",
        phone: "",
    });
    const [packages, setPackages] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");

    const { useViewUserMutation } = userManagement;
    const [viewUser] = useViewUserMutation();
    const { useViewWorkspaceMutation, useInviteUserMutation, useCreateWorkspaceMutation } = workspaceManagement;
    const [viewWorkspace] = useViewWorkspaceMutation();
    const [createWorkspace] = useCreateWorkspaceMutation();
    const [inviteUser] = useInviteUserMutation();
    const { usePackageListMutation } = packageManagement;
    const [packageList] = usePackageListMutation();

    const validateForm = () => {
        try {
            let isFormValid = true;
            const { name, packageName, admin, city } = workspaceInfo;

            if (!name?.trim() || !packageName?.trim() || !admin?.trim() || !city?.trim()) {
                isFormValid = false;
            }

            return isFormValid;
        } catch (error) {
            return false;
        }
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event?.target;

            setWorkspaceInfo({ ...workspaceInfo, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogoUpload = (logo) => {
        try {
            setWorkspaceInfo({ ...workspaceInfo, logo: logo });
        } catch (error) {
            console.log(error);
        }
    };

    const getPackageList = async () => {
        try {
            dispatch(setIsLoading(true));
            const response = await packageList()?.unwrap();
            setPackages(response.records);
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const getWorkspaceDetails = async (id) => {
        try {
            dispatch(setIsLoading(true));

            const response = await viewWorkspace(id).unwrap();
            const workspaceDetails = response?.info?.workspace;

            let userResponse = null;
            if (workspaceDetails?.admin) {
                userResponse = await viewUser(workspaceDetails?.admin).unwrap();
            }

            setWorkspaceInfo({
                ...workspaceInfo,
                logo: workspaceDetails?.logo || "",
                name: workspaceDetails?.name || "",
                packageName: workspaceDetails?.packageName || "",
                admin: userResponse?.info?.user?.email || "",
                country: workspaceDetails?.country || "",
                city: workspaceDetails?.city || "",
                workEmail: workspaceDetails?.workEmail || "",
                industry: workspaceDetails?.industry || "",
                phone: workspaceDetails?.phone || "",
            });

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleSave = async (event, workspaceInfo) => {
        try {
            event.preventDefault();

            const isFormValid = validateForm();
            if (!isFormValid) {
                setNotificationType("warning");
                setMessage("Please ensure that all required fields are completed.");
                setIsVisible(true);
                return;
            }

            dispatch(setIsLoading(true));

            const formData = new FormData();

            if (workspaceInfo?.logo) {
                formData.append("logo", workspaceInfo?.logo);
            }
            formData.append("name", workspaceInfo?.name);
            // formData.append("packageName", workspaceInfo?.packageName);
            formData.append("packageName", "FreeTrail");
            formData.append("country", workspaceInfo?.country);
            formData.append("city", workspaceInfo?.city);
            formData.append("industry", workspaceInfo?.industry);
            formData.append("workEmail", workspaceInfo?.workEmail);
            formData.append("phone", workspaceInfo?.phone);

            const response = await createWorkspace(formData)?.unwrap();

            const workspaceId = response?.info?.newWorkspace?._id;

            if (workspaceInfo?.admin) {
                const params = {
                    body: {
                        users: workspaceInfo?.admin,
                    },
                    workspaceId,
                };

                await inviteUser(params).unwrap();

                setMessage("Workspace created successfully.");
                setNotificationType("success");
                setIsVisible(true);

                navigate("/settings");
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    useEffect(() => {
        getPackageList();
        if (workspaceIdFromURL) {
            getWorkspaceDetails(workspaceIdFromURL);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main settings details">
                <div className="settings__container">
                    <div className="settings__btns-title--super">Details</div>
                    <div className="settings__btns-container--super">
                        <div className="settings__btns--super">
                            <div className="settings__super-square" onClick={() => navigate("/settings")}>
                                <i className="bi bi-chevron-left"></i>
                            </div>
                            <button
                                className="settings-super-btn active"
                                onClick={() => navigate("/settings/workspace-details")}
                                data-button-text="Details"
                            >
                                Details
                            </button>
                            {!!workspaceIdFromURL && (
                                <button
                                    className="settings-super-btn"
                                    onClick={() => navigate(`/settings/workspace-people/${workspaceIdFromURL}`)}
                                    data-button-text="People"
                                >
                                    People
                                </button>
                            )}
                        </div>
                        <button
                            className="settings-super-btn save"
                            data-button-text="Save"
                            onClick={(event) => handleSave(event, workspaceInfo)}
                            disabled={!!workspaceIdFromURL}
                        >
                            <i className="bi bi-floppy"></i> Save
                        </button>
                    </div>
                    <div className="settings__container__form-container">
                        <form
                            className="settings__container__form"
                            onSubmit={(event) => handleSave(event, workspaceInfo)}
                        >
                            <UploadImage
                                width={300}
                                height={300}
                                defaultImage={workspaceInfo?.logo}
                                options={{
                                    downloadMedia: !!workspaceIdFromURL,
                                    deleteMedia: workspaceIdFromURL ? false : true,
                                }}
                                onChangeImage={handleLogoUpload}
                                readOnly={!!workspaceIdFromURL}
                            />
                            <div className="settings__container__form-inputs">
                                <div className="settings__container__form-input full-width">
                                    <label htmlFor="name">Name*</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={workspaceInfo?.name}
                                        onChange={handleChange}
                                        required
                                        readOnly={!!workspaceIdFromURL}
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="country">Country*</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={workspaceInfo?.country}
                                        onChange={handleChange}
                                        required
                                        readOnly={!!workspaceIdFromURL}
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="packageName">Subscription*</label>
                                    <select
                                        id="packageName"
                                        name="packageName"
                                        onChange={handleChange}
                                        value={workspaceInfo?.packageName}
                                        required
                                        disabled={!!workspaceIdFromURL}
                                    >
                                        {packages.map((packageItem, index) => {
                                            if (packageItem?.name === "FreeTrial") {
                                                return;
                                            }

                                            return (
                                                <option key={index} value={packageItem?.name}>
                                                    {packageItem?.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="id">City*</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={workspaceInfo?.city}
                                        onChange={handleChange}
                                        required
                                        readOnly={!!workspaceIdFromURL}
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="admin">Workspace Admin*</label>
                                    <input
                                        type="email"
                                        id="admin"
                                        name="admin"
                                        value={workspaceInfo?.admin}
                                        onChange={handleChange}
                                        required
                                        readOnly={!!workspaceIdFromURL}
                                    />
                                </div>
                                <div className="settings__container__form-input">
                                    <label htmlFor="industry">Industry</label>
                                    <input
                                        type="text"
                                        id="industry"
                                        name="industry"
                                        value={workspaceInfo?.industry}
                                        onChange={handleChange}
                                        readOnly={!!workspaceIdFromURL}
                                    />
                                </div>
                                <div className="settings__container__form-subtitle">Contact info</div>
                                <div className="settings__container__form-input">
                                    <div className="form-input__container">
                                        <i className="bi bi-envelope"></i>
                                        <input
                                            type="email"
                                            id="workEmail"
                                            name="workEmail"
                                            value={workspaceInfo?.workEmail}
                                            onChange={handleChange}
                                            readOnly={!!workspaceIdFromURL}
                                        />
                                    </div>
                                </div>
                                <div className="settings__container__form-input">
                                    <div className="form-input__container">
                                        <i className="bi bi-telephone"></i>
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            value={workspaceInfo?.phone}
                                            onChange={handleChange}
                                            readOnly={!!workspaceIdFromURL}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                Powered by <span>QMind Solutions</span>
            </div>
            <div className="notification-list">
                <NotificationDialog
                    type={notificationType}
                    isVisible={isVisible}
                    message={message}
                    onClose={() => {
                        setIsVisible(false);
                        setMessage("");
                        setNotificationType("");
                    }}
                />
            </div>
        </DefaultLayout>
    );
};

export default WorkspaceDetails;
