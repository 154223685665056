import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DefaultLayout } from "../../layouts/Default";
import { Week } from "./components/Week";
import { DayDetails } from "./components/DayDetails";
import { MeetingForm } from "./components/MeetingForm";
import { meetingManagement } from "../../hooks/api/MeetingManagement";
import { setIsLoading } from "../../slices/Status";

const Calendar = () => {
    const mode = "calendar";
    const title = "My Calendar";
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setMinutes(0);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [weekRange, setWeekRange] = useState("");
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedMeetInfo, setSelectedMeetInfo] = useState(null);
    const [list, setList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [refresh, setRefresh] = useState(true);

    const dispatch = useDispatch();

    const { useMeetingListMutation } = meetingManagement;
    const [meetingList] = useMeetingListMutation();

    const handleSelectedDay = (newList) => {
        try {
            setSelectedList(newList);
            setIsFormVisible(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getMeetingList = async () => {
        try {
            dispatch(setIsLoading(true));

            const response = await meetingList().unwrap();
            setList(response.records);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleCurrentDate = (value) => {
        setCurrentDate(value);
    };

    const prevMonth = () => {
        setCurrentDate((prevDate) => {
            const prevMonthDate = new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, prevDate.getDate());
            return prevMonthDate;
        });
    };

    const nextMonth = () => {
        setCurrentDate((prevDate) => {
            const nextMonthDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, prevDate.getDate());
            return nextMonthDate;
        });
    };

    const prevWeek = () => {
        setCurrentDate((prevDate) => {
            const prevWeekDate = new Date(prevDate);
            prevWeekDate.setDate(prevWeekDate.getDate() - 7);
            return prevWeekDate;
        });
    };

    const nextWeek = () => {
        setCurrentDate((prevDate) => {
            const nextWeekDate = new Date(prevDate);
            nextWeekDate.setDate(nextWeekDate.getDate() + 7);
            return nextWeekDate;
        });
    };

    const handleCloseForm = () => {
        setSelectedMeetInfo(null);
        setIsFormVisible(false);
    };

    const handleSelectMeeting = (meetInfo) => {
        try {
            setSelectedMeetInfo(meetInfo);
            setIsFormVisible(true);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (refresh) {
            getMeetingList();
            setRefresh(false);
        }
        // eslint-disable-next-line
    }, [refresh]);

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main calendar">
                <div className="calendar__container">
                    <div className="calendar__header">
                        <div className="calendar__nav">
                            <i className="bi bi-chevron-double-left" onClick={prevMonth}></i>
                            <i className="bi bi-chevron-left" onClick={prevWeek}></i>
                            <span>{weekRange}</span>
                            <i className="bi bi-chevron-right" onClick={nextWeek}></i>
                            <i className="bi bi-chevron-double-right" onClick={nextMonth}></i>
                        </div>
                        <button
                            className="calendar__btn"
                            onClick={() => {
                                if (!currentDate.getTime() <= now) {
                                    setSelectedMeetInfo(null);
                                    setIsFormVisible(true);
                                }
                            }}
                            disabled={currentDate.getTime() <= now}
                        >
                            <i className="bi bi-plus"></i>Add Meeting
                        </button>
                    </div>
                    <div className="calendar__boxes">
                        <Week
                            currentDate={currentDate}
                            setCurrentDate={handleCurrentDate}
                            weekRange={weekRange}
                            setWeekRange={setWeekRange}
                            meetingList={list}
                            onSelectDay={handleSelectedDay}
                        />
                        <DayDetails onMeetClick={handleSelectMeeting} currentDate={currentDate} list={selectedList} />
                        {isFormVisible && (
                            <MeetingForm
                                isFormVisible={isFormVisible}
                                currentDate={currentDate}
                                selectedMeetInfo={selectedMeetInfo}
                                onClose={handleCloseForm}
                                setRefresh={(value) => setRefresh(value)}
                            />
                        )}
                    </div>
                </div>
            </main>
        </DefaultLayout>
    );
};

export default Calendar;
