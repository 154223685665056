import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PreLoader } from "../../components/PreLoader";
import { DefaultLayout } from "../../layouts/Default";
import { useTranslation } from "react-i18next";
import { meetingManagement } from "../../hooks/api/MeetingManagement";
import { setIsLoading } from "../../slices/Status";
import { Ok } from "../../dialogs/boxes/Ok";
import { ParticipantView } from "./components/ConferenceVideoPanel/components/ParticipantView";
import { ParticipantsList } from "./components/ParticipantsList";
import { AppContext } from "../../context/AppContext";

const constraints = {
    video: {
        frameRate: 15,
        aspectRatio: 16 / 9,
    },
    audio: false,
};

const trackVideo = "video";
const trackAudio = "audio";

const PreMeeting = () => {
    const { socket } = useContext(AppContext);
    const mode = "premeeting";
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const userInfo = useSelector((state) => state.user);

    const { meetingId } = useParams();

    const dispatch = useDispatch();

    const { useViewMeetingMutation } = meetingManagement;
    const [viewMeeting] = useViewMeetingMutation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalText, setModalText] = useState("");
    const [isMeetValid, setIsMeetValid] = useState(false);
    const [localStream, setLocalStream] = useState(null);

    const getMeetingDetails = async () => {
        try {
            if (!meetingId) {
                setIsMeetValid(false);
                return;
            }
            dispatch(setIsLoading(true));

            const response = await viewMeeting(meetingId).unwrap();

            const meetingData = response?.info?.message?.meeting;
            const startAt = meetingData?.startAt;
            const endAt = meetingData?.endAt;
            const now = new Date().getTime();

            if ((startAt <= now && endAt >= now) || (!startAt && !endAt)) {
                setIsMeetValid(true);
            } else {
                setIsMeetValid(false);
                setModalText("This meet has't started yet. Please, try again in schedule time.");
                setIsModalVisible(true);
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            // navigate("/404");
            setIsMeetValid(false);
            setModalText("Oops something went wrong. Please, try again later. Thank you.");
            setIsModalVisible(true);
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const getLocalStream = async () => {
        try {
            if (!localStream) {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);

                setLocalStream(stream);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleToggleMedia = (type) => {
        try {
            if (localStream) {
                let tracks = localStream?.getTracks();
                // tracks = tracks.forEach((track) => {
                //     if (type === track.kind) {
                //         track.enable = !track.enable;
                //     }
                // });
            }
        } catch (error) {}
    };

    useEffect(() => {
        // getLocalStream();
        getMeetingDetails();
        // eslint-disable-next-line

        return () => {
            // localStream?.getTracks()?.forEach((track) => track.stop());
        };
    }, []);

    return (
        <DefaultLayout mode={mode}>
            <main className="main welcome no-footer">
                <div className="main__container">
                    <div className="main__container-grid">
                        <div className="grid-sizer"></div>
                        <div className="gutter-sizer"></div>
                        <PreLoader />
                        <ParticipantView
                            avatar={userInfo?.user?.avatar}
                            firstName={userInfo?.user?.firstName}
                            lastName={userInfo?.user?.lastName}
                            pinnedParticipant="none"
                            stream={localStream}
                            isMuted={true}
                            renderVideoOverlay={() => (
                                <>
                                    <div className="main__container-video-overlay-btn main__container-btn-hover main__container-video-overlay-btn-rec">
                                        <i className="bi bi-record-circle"></i>
                                        <span>24:01:45</span>
                                    </div>
                                    <div
                                        onClick={() => handleToggleMedia(trackVideo)}
                                        className="main__container-video-overlay-btn main__container-video-overlay-btn-video button-cam active"
                                    >
                                        <i className="bi bi-camera-video"></i>
                                    </div>
                                    <div
                                        onClick={() => handleToggleMedia(trackAudio)}
                                        className="main__container-video-overlay-btn main__container-video-overlay-btn-sound button-mic active"
                                    >
                                        <i className="bi bi-mic"></i>
                                    </div>
                                    <div className="main__container-video-overlay-btn main__container-video-overlay-btn-fullscreen">
                                        <i className="bi bi-fullscreen"></i>
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="main__container-welcome">
                    {socket?.connected && <ParticipantsList />}
                    <div
                        className="home-btn welcome"
                        onClick={() => {
                            if (isMeetValid) {
                                navigate(`/meeting/${meetingId}`);
                            }
                        }}
                    >
                        {t("common:join")}
                    </div>
                </div>
            </main>
            <div className="welcome__container-description">
                {t("common:founderText")} <span>{t("common:founderName")}</span>
            </div>
            <Ok isVisible={isModalVisible} title="Meeting" description={modalText} disableClose={true} />
        </DefaultLayout>
    );
};

export default PreMeeting;
