import { useEffect, useState } from "react";

const SearchForm = ({ onSearch }) => {
    const [searchKey, setSearchKey] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    const handleChange = (event) => {
        setSearchKey(event.target.value);
        setIsTyping(true);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isTyping) {
                onSearch(searchKey);
                setIsTyping(false);
            }
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line
    }, [isTyping, searchKey]);

    return (
        <form className="settings__container__form-inputs m-0">
            <div className="settings__container__form-input full-width">
                <div className="form-input__container">
                    <i className="bi bi-search"></i>
                    <input type="text" id="searchKey" name="searchKey" value={searchKey} onChange={handleChange} />
                    <div className={`loader-locale ${isTyping ? "" : "inactive"}`}></div>
                </div>
            </div>
        </form>
    );
};

export default SearchForm;
