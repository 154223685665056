import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "../TimePicker";
import { AddPeople } from "./modals/AddPeople";
import { setIsLoading } from "../../../../slices/Status";
import { meetingManagement } from "../../../../hooks/api/MeetingManagement";
import { workspaceManagement } from "../../../../hooks/api/WorkspaceManagement";
import { useUserToken } from "../../../../hooks/core/UserToken";
import { ParticipantList } from "../DayDetails/components/MeetingDetails/components/ParticipantList";
import { YesNo } from "../../../../dialogs/boxes/YesNo";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";

const MeetingForm = ({ isFormVisible, currentDate, selectedMeetInfo, onClose, setRefresh }) => {
    const [selectedDate, setSelectedDate] = useState("");
    const [meetInfo, setMeetInfo] = useState({
        id: "",
        title: "",
        startAt: 0,
        endAt: 0,
        duration: "",
        description: "",
        participants: [],
    });
    const [guestes, setGuestes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [disabledType, setDisabledType] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const now = new Date().getTime();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userToken] = useUserToken();
    const token = userToken.getDecoded();

    const { useCreateMeetingMutation, useUpdateMeetingMutation, useDeleteMeetingMutation } = meetingManagement;
    const [createMeeting] = useCreateMeetingMutation();
    const [updatedMeeting] = useUpdateMeetingMutation();
    const [deleteMeeting] = useDeleteMeetingMutation();
    const { useInviteUserMutation } = workspaceManagement;
    const [inviteUser] = useInviteUserMutation();

    const handleSelectTime = (name, value) => {
        try {
            setMeetInfo({ ...meetInfo, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectedDate = () => {
        try {
            const date = new Date(currentDate);

            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();

            const formattedDate = `${day}.${month}.${year}.`;
            setSelectedDate(formattedDate);
        } catch (error) {
            console.log(error);
        }
    };

    const getHoursAndMinutes = (time) => {
        try {
            if (!time) {
                return 0;
            }

            const newTime = new Date(JSON.parse(time));
            let hours = newTime?.getHours();
            let minutes = newTime.getMinutes();

            let timeInMiliSeconds = (hours * 60 + minutes) * 60 * 1000;

            return timeInMiliSeconds;
        } catch (error) {
            console.log(error);
        }
    };

    const handleReverseChanges = () => {
        try {
            let startAt = 0;
            let endAt = 0;

            const initialParticipant = token.id;

            if (selectedMeetInfo?.startAt && selectedMeetInfo?.endAt) {
                startAt = getHoursAndMinutes(selectedMeetInfo?.startAt);
                endAt = getHoursAndMinutes(selectedMeetInfo?.endAt);
            }

            setMeetInfo({
                id: selectedMeetInfo?._id || "",
                title: selectedMeetInfo?.title || "",
                startAt: startAt || 0,
                endAt: endAt || 0,
                duration: selectedMeetInfo?.duration || "",
                description: selectedMeetInfo?.description || "",
                participants: selectedMeetInfo?.participants || [initialParticipant],
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectUsers = (participants) => {
        try {
            setMeetInfo({ ...meetInfo, participants: participants });
            setIsModalVisible(false);
        } catch (error) {}
    };

    const handleRemoveUser = (user) => {
        try {
            const updatedUsers = meetInfo?.participants?.filter((participantId) => participantId !== user.id);
            setMeetInfo({ ...meetInfo, participants: updatedUsers });
        } catch (error) {}
    };

    const convertToTimestamp = (date) => {
        try {
            let newDate = new Date(date);

            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);

            const timestamp = newDate.getTime();

            return timestamp;
        } catch (error) {
            console.log(error);

            return "";
        }
    };

    const handleNotification = (isVisible, message) => {
        try {
            setNotificationMessage(message);
            setShowNotification(isVisible);
        } catch (error) {
            console.log(error);
        }
    };

    const validateForm = (meetInfo) => {
        try {
            let now = new Date().getTime();

            const date = convertToTimestamp(currentDate);
            const startAt = date + JSON.parse(meetInfo.startAt);
            const endAt = date + JSON.parse(meetInfo.endAt);

            if (startAt >= endAt) {
                handleNotification(true, "The end time of meeting must be greater then start time.");
                return false;
            }

            if (startAt < now || endAt < now) {
                handleNotification(true, "Meeting can't be created in past time.");
                return false;
            }

            if (meetInfo?.participants?.length === 0) {
                handleNotification(true, "Please add participants in this meeting.");
                return false;
            }

            return true;
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            dispatch(setIsLoading(true));

            const isFormValid = validateForm(meetInfo);

            if (!isFormValid) {
                dispatch(setIsLoading(false));
                return;
            }

            const date = convertToTimestamp(currentDate);
            const startAt = date + JSON.parse(meetInfo.startAt);
            const endAt = date + JSON.parse(meetInfo.endAt);

            let meetingId = "";

            if (!!selectedMeetInfo) {
                const body = {
                    title: meetInfo.title,
                    startAt: startAt,
                    endAt: endAt,
                    participants: meetInfo.participants,
                    description: meetInfo.description,
                    admin: token.id,
                };

                const response = await updatedMeeting({
                    body,
                    param: meetInfo.id,
                }).unwrap();

                meetingId = response?.info?.meeting?._id;
            } else {
                const body = {
                    title: meetInfo.title,
                    startAt: startAt,
                    endAt: endAt,
                    participants: meetInfo.participants,
                    description: meetInfo.description,
                    admin: token.id,
                };

                const response = await createMeeting(body).unwrap();

                meetingId = response?.info?.meeting?._id;
            }

            if (meetingId && guestes?.length === 1) {
                const workspaceId = sessionStorage.getItem("workspaceId");

                const params = {
                    body: {
                        users: guestes[0].email,
                        meetingId: meetingId,
                    },
                    workspaceId,
                };
                await inviteUser(params).unwrap();
            }

            onClose();
            setRefresh(true);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
            handleNotification(true, "Oops! Time slot overlaps with existing meeting. Please choose another time.");
        }
    };

    const handleCancelMeet = async (id) => {
        try {
            dispatch(setIsLoading(true));

            if (!id) {
                return;
            }

            await deleteMeeting(id).unwrap();
            dispatch(setIsLoading(false));

            onClose();
            setRefresh(true);
        } catch (error) {
            console.log(error);
            handleNotification(true, "Oops! Something went wrong. Please, try again.");
            dispatch(setIsLoading(false));
        }
    };

    const handleDisableType = (meetInfo) => {
        try {
            let type = "";

            if (meetInfo) {
                const { startAt, endAt } = meetInfo;

                if (startAt <= now && endAt > now) {
                    type = "during-meet";
                } else if (meetInfo.admin !== token.id) {
                    type = "not-meet-admin";
                } else if (now >= endAt) {
                    type = "after-meet";
                } else if (now < startAt) {
                    type = "before-meet";
                }
            } else {
                type = "new-meet";
            }

            setDisabledType(type);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleSelectedDate();
        // eslint-disable-next-line
    }, [currentDate]);

    useEffect(() => {
        handleReverseChanges();

        handleDisableType(selectedMeetInfo);

        // eslint-disable-next-line
    }, [selectedMeetInfo]);

    return (
        <div className={`calendar__box ${isFormVisible ? "open-modal" : ""}`}>
            <div className="calendar__box__form-container">
                <div className="calendar__box-wrapper">
                    <form className="calendar__box__form" onSubmit={handleSubmit}>
                        <div className="calendar__box__form-title">
                            <div className="calendar__box__form-input">
                                <label htmlFor="title">Title</label>
                                <input
                                    id="title"
                                    type="text"
                                    name="title"
                                    value={meetInfo?.title}
                                    onChange={(event) => {
                                        setMeetInfo({ ...meetInfo, title: event.target.value });
                                    }}
                                    disabled={
                                        disabledType === "during-meet" ||
                                        disabledType === "after-meet" ||
                                        disabledType === "not-meet-admin"
                                    }
                                    required
                                />
                            </div>
                            {disabledType === "during-meet" && (
                                <button
                                    type="button"
                                    className="calendar__btn join"
                                    onClick={() => {
                                        if (meetInfo?.id) {
                                            navigate(`/premeeting/${meetInfo.id}`);
                                        }
                                    }}
                                >
                                    Join the meeting
                                </button>
                            )}
                            {disabledType === "before-meet" && (
                                <button
                                    type="button"
                                    className="calendar__btn cancel"
                                    onClick={() => setIsModalVisible2(true)}
                                >
                                    Cancel the meeting
                                </button>
                            )}
                        </div>
                        <div className="calendar__box__form-date">
                            <label>Date:</label>
                            <input type="text" placeholder={selectedDate} readOnly required />
                        </div>
                        <TimePicker
                            onSelectTime={handleSelectTime}
                            startAt={meetInfo.startAt}
                            endAt={meetInfo?.endAt}
                            disabled={
                                disabledType === "during-meet" ||
                                disabledType === "after-meet" ||
                                disabledType === "not-meet-admin"
                            }
                        />
                        <div className="calendar__box__form-participants">
                            <label>Participants:</label>
                            <div className="participants-container">
                                <ParticipantList
                                    type="form"
                                    listIds={meetInfo.participants}
                                    onRemoveUser={handleRemoveUser}
                                    disabled={
                                        disabledType === "during-meet" ||
                                        disabledType === "after-meet" ||
                                        disabledType === "not-meet-admin"
                                    }
                                />
                                <button
                                    type="button"
                                    className="calendar__box__form__add-btn"
                                    onClick={() => {
                                        if (disabledType === "before-meet" || disabledType === "new-meet") {
                                            setIsModalVisible(true);
                                        }
                                    }}
                                >
                                    <i className="bi bi-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div
                            className="calendar__box__form-textarea"
                            style={{
                                marginBottom:
                                    disabledType === "after-meet" ||
                                    disabledType === "during-meet" ||
                                    disabledType === "not-meet-admin"
                                        ? 0
                                        : 30,
                            }}
                        >
                            <label htmlFor="agenda">Agenda:</label>
                            <textarea
                                id="agenda"
                                value={meetInfo?.description}
                                onChange={(event) => {
                                    setMeetInfo({ ...meetInfo, description: event.target.value });
                                }}
                                rows={3}
                                disabled={
                                    disabledType === "after-meet" ||
                                    disabledType === "during-meet" ||
                                    disabledType === "not-meet-admin"
                                }
                            ></textarea>
                        </div>
                        <div className="calendar__box__form-btns">
                            {disabledType === "before-meet" && (
                                <button
                                    type="button"
                                    className="calendar__box__reverse-btn"
                                    onClick={handleReverseChanges}
                                >
                                    Reverse changes
                                </button>
                            )}
                            {(disabledType === "new-meet" || disabledType === "before-meet") && (
                                <button className="calendar__box__save-btn" type="submit">
                                    Save
                                </button>
                            )}
                        </div>
                        {/* <i className="bi bi-caret-left-fill arrow-pointer"></i> */}
                        <i className="bi bi-x-lg close-icon" onClick={onClose}></i>
                    </form>
                </div>
            </div>
            <AddPeople
                isVisible={isModalVisible}
                title="Add people"
                onCancel={() => setIsModalVisible(false)}
                onConfirm={handleSelectUsers}
                preSelectedUsers={meetInfo?.participants}
                onSelectGuestes={(newGuest) => setGuestes([...guestes, newGuest])}
            />
            <YesNo
                isVisible={isModalVisible2}
                onCancel={() => {
                    setIsModalVisible2(false);
                }}
                onConfirm={() => handleCancelMeet(selectedMeetInfo?._id)}
                text="Are you sure you want to cancel the meeting?"
            />
            <div className="notification-list">
                <NotificationDialog
                    isVisible={showNotification}
                    message={notificationMessage}
                    onClose={() => {
                        handleNotification(false, "");
                    }}
                />
            </div>
        </div>
    );
};

export default MeetingForm;
