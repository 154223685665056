import { useNavigate } from "react-router-dom";
import { meetingManagement } from "../../../../hooks/api/MeetingManagement";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../../../slices/Status";

const MeetingDropdown = ({ isVisible, onCreateMeetForLater, onCloseDropdown }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { useCreateMeetingMutation } = meetingManagement;
    const [createMeet] = useCreateMeetingMutation();

    const handleCreateMeet = async (type) => {
        try {
            dispatch(setIsLoading(true));

            const response = await createMeet().unwrap();

            const meetingId = response?.info?.meeting?._id;
            const meetingLink =
                type === "instant-meet"
                    ? `/meeting/${meetingId}`
                    : `${process.env.REACT_APP_URL}/premeeting/${meetingId}`;

            dispatch(setIsLoading(false));

            return meetingLink;
        } catch (error) {
            console.log(error);
            return type === "instant-meet" ? "/" : process.env.REACT_APP_URL;
        }
    };

    const handleCreateForLaterMeet = async () => {
        try {
            onCloseDropdown();

            const meetingLink = await handleCreateMeet();

            onCreateMeetForLater(meetingLink);
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    const handleInstantMeet = async () => {
        try {
            const meetingLink = await handleCreateMeet("instant-meet");
            navigate(meetingLink);
        } catch (error) {
            navigate("/meeting");
            console.log(error);
        }
    };

    const handleScheduleMeet = () => {
        try {
            navigate("/calendar");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="side-menu__dropdown new-meeting" style={{ height: isVisible ? 3 * 45 : 0, width: 280 }}>
            <ul className="side-menu__list">
                <li className="side-menu__item" onClick={handleCreateForLaterMeet}>
                    <i className="bi bi-link"></i> <span>Create a meeting for later</span>
                </li>
                <li className="side-menu__item" onClick={handleInstantMeet}>
                    <i className="bi bi-plus-lg"></i> <span>Start an instant meeting</span>
                </li>
                <li className="side-menu__item" onClick={handleScheduleMeet}>
                    <i className="bi bi-calendar4"></i> <span>Schedule in your calendar</span>
                </li>
            </ul>
        </div>
    );
};

export default MeetingDropdown;
