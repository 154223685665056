import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUserRole } from "../../../../hooks/core/UserRole";

import logo1 from "../../../../assets/images/qmeet_logo_1.svg";
import logo2 from "../../../../assets/images/qmeet_logo_2.svg";

import LobbyItem from "../../../../pages/Lobby/components/LobbyItem/LobbyItem";

const SidebarMenu = ({ onSidebarAction, isChatVisible, mode, getUserInfo }) => {
    const [activeTab, setActiveTab] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const navigate = useNavigate();

    const userInfo = useSelector((state) => state.user);
    const isPaid = userInfo?.workspace?.paid;
    const role = useUserRole();

    useEffect(() => {
        setActiveTab(mode);
    }, [mode]);

    return (
        <aside className={`aside-left ${mode}`}>
            <div className="aside-left__content">
                <div className="footer__content-video-control-btn aside-logo">
                    <div
                        className={`side-menu-logo ${userInfo?.workspace?.logo ? "contain-logo" : ""}`}
                        onClick={() => {
                            if (userInfo?.user?.workspaces?.filter((workspace) => workspace.workspace)?.length > 1) {
                                setShowDropdown((prevState) => !prevState);
                            }
                        }}
                    >
                        {userInfo?.workspace?.logo ? (
                            <img
                                className="logo"
                                src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${userInfo?.workspace?.logo}`}
                                alt=""
                            />
                        ) : (
                            <img src={logo1} alt="" />
                        )}
                    </div>
                    {userInfo?.user?.workspaces?.filter((workspace) => workspace.workspace)?.length > 1 && (
                        <div
                            className="side-menu__dropdown"
                            style={{
                                height: showDropdown
                                    ? userInfo?.user?.workspaces?.filter((workspace) => workspace.workspace)?.length *
                                      61
                                    : 0,
                            }}
                        >
                            <ul className="side-menu__list">
                                {userInfo?.user?.workspaces?.map((workspace, index) => {
                                    if (workspace?.workspace) {
                                        return (
                                            <LobbyItem
                                                type="dropdown-list"
                                                workspaceId={workspace.workspace}
                                                isActive={sessionStorage.getItem("workspaceId") === workspace.workspace}
                                                onCloseDropdown={() => setShowDropdown(false)}
                                                getUserInfo={getUserInfo}
                                                key={index}
                                            />
                                        );
                                    }

                                    return;
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="aside-left__content-pages">
                    {userInfo?.user?.workspaces?.length > 0 && isPaid && (
                        <>
                            {mode !== "meeting" && mode !== "lobby" && role !== "super admin" && (
                                <div
                                    className={`footer__content-video-control-btn ${activeTab === "home" ? "active" : ""}`}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    <i className="bi bi-house"></i>
                                </div>
                            )}
                            {mode === "meeting" && (
                                <div
                                    className={`footer__content-video-control-btn ${activeTab === "camera" ? "active" : ""}`}
                                    onClick={() => {
                                        setActiveTab("camera");
                                    }}
                                >
                                    <i className="bi bi-camera-video"></i>
                                </div>
                            )}
                            {mode === "meeting" && (
                                <div
                                    className={`footer__content-video-control-btn ${isChatVisible ? "active" : ""}`}
                                    id="chat-control"
                                    onClick={() => {
                                        if (mode === "meeting") {
                                            onSidebarAction("toggle-chat");
                                        }
                                    }}
                                >
                                    {isChatVisible ? (
                                        <i className="bi bi-chat-square-dots"></i>
                                    ) : (
                                        <i className="bi bi-chat-square"></i>
                                    )}
                                </div>
                            )}
                            {mode !== "meeting" && mode !== "lobby" && role !== "super admin" && (
                                <div
                                    className={`footer__content-video-control-btn ${activeTab === "calendar" ? "active" : ""}`}
                                    onClick={() => {
                                        navigate("/calendar");
                                    }}
                                >
                                    <i className="bi bi-calendar3"></i>
                                </div>
                            )}
                            {mode !== "meeting" && mode !== "lobby" && role !== "super admin" && (
                                <div
                                    className={`footer__content-video-control-btn ${activeTab?.includes("subscription") ? "active" : ""} subscription-icon`}
                                    onClick={() => navigate("/subscription")}
                                >
                                    {!isPaid && <i className="bi bi-circle-fill circle-icon"></i>}
                                    <i className="bi bi-credit-card"></i>
                                </div>
                            )}
                            {mode !== "meeting" && mode !== "lobby" && (role === "admin" || role === "super admin") && (
                                <div
                                    className={`footer__content-video-control-btn ${activeTab?.includes("admin") ? "active" : ""}`}
                                    onClick={() => {
                                        navigate("/settings");
                                    }}
                                >
                                    <i className="bi bi-gear"></i>
                                </div>
                            )}
                        </>
                    )}
                    {role === "super admin" && (
                        <div
                            className={`footer__content-video-control-btn ${activeTab?.includes("admin") ? "active" : ""}`}
                            onClick={() => {
                                navigate("/settings");
                            }}
                        >
                            <i className="bi bi-gear"></i>
                        </div>
                    )}
                </div>
                <div className="footer__content-video-control-btn aside-logo">
                    <div className="side-menu-logo">
                        <img src={logo2} alt="" />
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default SidebarMenu;
