import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userSlice } from "../slices/User";
import { statusSlice } from "../slices/Status";
import { loginManagement } from "../hooks/api/LoginManagement/LoginManagement";
import { workspaceManagement } from "../hooks/api/WorkspaceManagement/WorkspaceManagement";
import { userManagement } from "../hooks/api/UserManagement/UserManagement";
import { paymentManagement } from "../hooks/api/PaymentManagement";
import { packageManagement } from "../hooks/api/PackageManagement";
import { meetingManagement } from "../hooks/api/MeetingManagement";

export const store = configureStore({
    reducer: {
        [userSlice.name]: userSlice.reducer,
        [statusSlice.name]: statusSlice.reducer,
        [loginManagement.reducerPath]: loginManagement.reducer,
        [userManagement.reducerPath]: userManagement.reducer,
        [workspaceManagement.reducerPath]: workspaceManagement.reducer,
        [paymentManagement.reducerPath]: paymentManagement.reducer,
        [packageManagement.reducerPath]: paymentManagement.reducer,
        [meetingManagement.reducerPath]: meetingManagement.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(loginManagement.middleware)
            .concat(workspaceManagement.middleware)
            .concat(userManagement.middleware)
            .concat(paymentManagement.middleware)
            .concat(packageManagement.middleware)
            .concat(meetingManagement.middleware),
});

setupListeners(store.dispatch);
