import { useTranslation } from "react-i18next";
import { Overlay } from "../../../components/Overlay";
import notificationIcon from "../../../assets/images/notification.svg";

const YesNo = ({ isVisible, onCancel, onConfirm, labelConfirm, labelCancel, text, maxWidth = 600 }) => {
    const { t } = useTranslation("common");

    return (
        <Overlay isVisible={isVisible}>
            <div className={`confirm-modal ${isVisible ? "open" : ""}`} style={{ maxWidth: maxWidth }}>
                <div className="confirm__img">
                    <img src={notificationIcon} alt="Notification icon" />
                </div>
                <div className="confirm__text">
                    {text || (
                        <>
                            The <span>MVSOFT</span> workspace has already reached the maximum number od users for
                            <span> Business</span> package. To add more users, upgrade your workspace package.
                        </>
                    )}
                </div>
                <div className="confirm__btns">
                    <button onClick={onCancel}>{labelCancel || t("common:no")}</button>
                    <button className="confirm" onClick={onConfirm}>
                        {labelConfirm || t("common:yes")}
                    </button>
                </div>
            </div>
        </Overlay>
    );
};

export default YesNo;
