import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketIo = io("http://localhost:8000"); // ili tvoj server URL
        setSocket(socketIo);

        console.log("Socket instance:", socketIo); // Dodaj ovo za debagovanje

        return () => {
            socketIo.disconnect();
        };
    }, []);

    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
