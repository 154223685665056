import { TimeInput } from "./components/TimeInput";

const TimePicker = ({ label = "Time:", onSelectTime, startAt, endAt, disabled }) => {
    return (
        <div className="time-picker">
            <div className="time-picker__label">{label}</div>
            <div className="time-picker__form">
                <div className="time-picker__form__inputs">
                    <TimeInput
                        onSelectTime={(value) => onSelectTime("startAt", value)}
                        time={startAt}
                        disabled={disabled}
                    />
                    <span> to </span>
                    <TimeInput
                        onSelectTime={(value) => onSelectTime("endAt", value)}
                        time={endAt}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default TimePicker;
