import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../layouts/Default";
import { useUserToken } from "../../hooks/core/UserToken";
import { useUserRole } from "../../hooks/core/UserRole";
import { workspaceManagement } from "../../hooks/api/WorkspaceManagement";
import { setIsLoading } from "../../slices/Status";
import { PackageList } from "./components/PackageList";
import { YesNo } from "../../dialogs/boxes/YesNo";

import meetingImage from "../../assets/images/login-image.png";
import failedIcon from "../../assets/images/activation-failed.svg";
import warningIcon from "../../assets/images/notification.svg";

const Subscription = () => {
    const { t } = useTranslation("common");

    const mode = "subscription";
    const title = t("common:subscription.title");

    const navigate = useNavigate();

    const [viewMode, setViewMode] = useState("");
    const [isVisible, setIsVisible] = useState(false);

    const { useDeleteWorkspaceMutation } = workspaceManagement;
    const [deleteWorkspace] = useDeleteWorkspaceMutation();

    const [userToken] = useUserToken();
    const role = useUserRole();

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user);
    const workspace = userInfo?.workspace;
    const packageName = workspace?.packageName;
    const workspaceName = workspace?.name;
    const isPaid = workspace?.paid;

    const handleDeleteWorkspace = async () => {
        try {
            dispatch(setIsLoading(true));
            if (userInfo?.workspace?._id) {
                await deleteWorkspace(userInfo?.workspace?._id).unwrap();

                setIsVisible(false);
                sessionStorage.removeItem("workspaceId");
                userToken.removeToken();
                dispatch(setIsLoading(false));
                navigate("/sign-up/login");
            }
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (!isPaid && workspace?._id) {
            setViewMode("deactive");
        } else {
            if (role !== "user") {
                setViewMode("active");
            } else {
                setViewMode("introduction");
            }
        }
        // eslint-disable-next-line
    }, [role, workspace]);

    return (
        <DefaultLayout mode={mode} title={title}>
            <main className="main settings subscription">
                <div className={`settings__container ${viewMode === "active" ? "" : "intro"}`}>
                    {viewMode === "deactive" ? (
                        <div className="main__activation__cotainer">
                            <div className="main__activation__img">
                                <img src={role === "user" ? warningIcon : failedIcon} alt="" />
                            </div>
                            <div className="main__activation__title">
                                {packageName === "FreeTrial" &&
                                    role === "admin" &&
                                    t("common:subscription.endFreeTrial")}
                                {packageName !== "FreeTrial" && role === "admin" && (
                                    <>
                                        {t("common:the")} {workspaceName} {t("common:subscription.notPaid")}{" "}
                                        <span className="cursor-pointer" onClick={() => setViewMode("active")}>
                                            {t("common:continueHere")}
                                        </span>
                                    </>
                                )}
                                {packageName && role === "user" && (
                                    <>
                                        {t("common:the")} {workspaceName} {t("common:subscription.deactivated")}{" "}
                                        <span className="cursor-pointer" onClick={() => setViewMode("active")}>
                                            {t("common:continueHere")}
                                        </span>
                                    </>
                                )}
                            </div>
                            {role === "admin" && (
                                <div className="activation-btns d-flex align-items-center justify-content-between w-100 gap-3 flex-wrap">
                                    <button
                                        className="activation-btn cancel"
                                        type="button"
                                        onClick={() => setIsVisible(true)}
                                    >
                                        {t("common:delete")}
                                    </button>
                                    <button
                                        className="activation-btn"
                                        type="button"
                                        onClick={() => setViewMode("active")}
                                    >
                                        {t("common:continue")}
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : viewMode === "active" ? (
                        <div className="settings__container-title">
                            {packageName === "FreeTrial" && isPaid && role !== "user" && (
                                <>
                                    {t("common:subscription.freeTrialText")}
                                    <br />
                                </>
                            )}
                            {t("common:subscription.subtitle")}
                        </div>
                    ) : (
                        <div className="settings__container-intro">
                            <img src={meetingImage} alt="" />
                            <div className="settings__container-title semibold">
                                {t("common:subscription.question")} {t("common:continue")}{" "}
                                <span onClick={() => setViewMode("active")}>{t("common:here")}</span>.
                            </div>
                        </div>
                    )}
                    {viewMode === "active" && <PackageList />}
                </div>
            </main>
            <div className="welcome__container-description">
                {t("common:founderText")} <span>{t("common:founderName")}</span>
            </div>
            <YesNo
                isVisible={isVisible}
                text={t("common:yesNo.eraseWorkspace")}
                onCancel={() => setIsVisible(false)}
                onConfirm={handleDeleteWorkspace}
            />
        </DefaultLayout>
    );
};

export default Subscription;
