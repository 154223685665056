import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const loginManagement = createApi({
    reducerPath: "loginManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: "login",
                method: "POST",
                body: body,
            }),
        }),
        signup: builder.mutation({
            query: (body) => ({
                url: "signup",
                method: "POST",
                body: body,
            }),
        }),
        verifyAccount: builder.mutation({
            query: (emailToken) => ({
                url: `verify-email/${emailToken}`,
                method: "GET",
            }),
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: "users/forgotten-password",
                method: "POST",
                body: email,
            }),
        }),
        resetPassword: builder.mutation({
            query: ({ body, param }) => {
                return {
                    url: `users/reset-password/${param}`,
                    method: "PATCH",
                    body: body,
                };
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useSignupMutation,
    useVerifyAccountMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = loginManagement;
