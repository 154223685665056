import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentManagement } from "../../../../hooks/api/PaymentManagement/PaymentManagement";
import { useUserRole } from "../../../../hooks/core/UserRole";
import { setIsLoading } from "../../../../slices/Status";

import moreInfoIcon from "../../../../assets/images/additionalInfo.svg";

const PackageItem = ({ item, index, freeTrialPackage }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user);
    const packageName = userInfo?.workspace?.packageName;
    const role = useUserRole();

    const { useCreateStripeSessionMutation } = paymentManagement;
    const [createStripeSession] = useCreateStripeSessionMutation();

    const handleSelect = async () => {
        try {
            dispatch(setIsLoading(true));

            if (!userInfo?.workspace?._id || role === "user") {
                navigate(`/subscription/workspace/${item._id}`);
                return;
            }

            const response = await createStripeSession({
                workspace: userInfo?.workspace?.name,
                name: item?.name,
                price: item.price * 100,
            }).unwrap();

            const stripeSessionUrl = response?.info?.sessionUrl || response?.sessionUrl;

            if (stripeSessionUrl) {
                window.location = stripeSessionUrl;
                dispatch(setIsLoading(false));
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            console.log(error);
        }
    };

    return (
        <div className={`settings__container__card-subscription ${index % 2 === 1 ? "light" : ""}`}>
            <div className="card-subscription__title">{item.name}</div>
            <div className={`card-subscription__subtitle ${item?.name === "Business" ? "price" : ""}`}>
                ${item.price}
                {item?.name === "Business" && (
                    <span>
                        USD/Creator/mo (annually) <img src={moreInfoIcon} alt="More information icon" />
                    </span>
                )}
            </div>
            <button className="settings-btn" onClick={handleSelect}>
                {role === "user" || !packageName || packageName === "FreeTrial"
                    ? "Subscribe"
                    : packageName === item.name
                      ? "Cancel"
                      : item.name === "Enterprise"
                        ? "Upgrade"
                        : "Switch"}
            </button>
            <div className="card-subscription__description">Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.</div>
            <hr />
            <ul className="card-subscription__list">
                <li className="card-subscription__item">Lorem ipsum dolor sit amet</li>
                <li className="card-subscription__item">Lorem ipsum dolor sit amet</li>
                <li className="card-subscription__item">Lorem ipsum dolor sit amet</li>
            </ul>
            {item.name === "Starter" && !packageName && freeTrialPackage && (
                <div
                    className="card-subscription__free"
                    onClick={() => navigate(`/subscription/workspace/${freeTrialPackage?._id}`)}
                >
                    Try out free Starter version for 14 days
                </div>
            )}
        </div>
    );
};

export default PackageItem;
