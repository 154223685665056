import { useEffect, useRef } from "react";

const ParticipantView = ({
    id,
    avatar,
    firstName = "",
    lastName = "",
    pinnedParticipant,
    onPinParticipant,
    stream,
    isMuted = false,
    renderVideoOverlay,
}) => {
    const isPinned = pinnedParticipant === id;

    const videoRef = useRef();

    useEffect(() => {
        if (stream && videoRef?.current) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    return (
        <div
            className={`main__container-video ${pinnedParticipant === "none" ? "" : isPinned ? "active" : "inactive"}`}
        >
            <div className="number">{pinnedParticipant === "none" ? "" : isPinned ? 0 : 1}</div>
            <div className="video-placeholder">
                {/* <!-- <i className="bi bi-person-video"></i> --> */}
                <div className="video-placeholder__avatar">
                    <div className="video-placeholder__avatar-initials">
                        <span>{firstName?.length > 0 && firstName[0]}</span>
                        <span>{lastName?.length > 0 && lastName[0]}</span>
                    </div>
                </div>
                {avatar && <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${avatar}`} alt="" />}
                <video muted={isMuted} autoPlay ref={videoRef}></video>
                {/* <!-- <img src="assets/img/video-1.jpg" alt="" /> --> */}
                <div className="main__container-video-overlay">
                    {renderVideoOverlay ? (
                        renderVideoOverlay()
                    ) : (
                        <>
                            <div className="main__container-video-overlay-btn main__container-btn-hover main__container-video-overlay-btn-rec">
                                <i className="bi bi-record-circle"></i>
                                <span>24:01:45</span>
                            </div>
                            <div
                                className="main__container-video-overlay-btn main__container-video-overlay-btn-name"
                                onClick={() => {
                                    onPinParticipant(id);
                                }}
                            >
                                <i className="bi bi-pin"></i>
                                <span>
                                    {firstName} {lastName}
                                </span>
                            </div>
                            <div className="main__container-video-overlay-btn main__container-btn-hover main__container-video-overlay-btn-sound button-mic active">
                                <i className="bi bi-mic"></i>
                            </div>
                            <div className="main__container-video-overlay-btn main__container-btn-hover main__container-video-overlay-btn-fullscreen">
                                <i className="bi bi-fullscreen"></i>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ParticipantView;
