import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsLoading } from "../../../../slices/Status";
import { MeetingItem } from "../MeetingItem";
import { meetingManagement } from "../../../../hooks/api/MeetingManagement";

const MeetingList = ({ selectedDate }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation("common");

    const [list, setList] = useState([]);

    const { useMeetingListMutation } = meetingManagement;
    const [meetingList] = useMeetingListMutation();

    const convertToTimestamp = (date, type) => {
        try {
            let timestamp = 0;
            let newDate = new Date(date);

            if (type === "end") {
                newDate.setDate(newDate.getDate() + 1);
            }

            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);

            timestamp = newDate.getTime();

            return timestamp;
        } catch (error) {
            console.log(error);

            return "";
        }
    };

    const getMeetingList = async (selectedDate) => {
        try {
            dispatch(setIsLoading(true));

            const endOfDay = convertToTimestamp(selectedDate, "end");
            const startOfDay = convertToTimestamp(selectedDate);
            const response = await meetingList().unwrap();

            let list = response.records;
            list = list.filter((item) => item.startAt >= startOfDay && item.endAt < endOfDay);

            setList(list);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        getMeetingList(selectedDate);
        // eslint-disable-next-line
    }, [selectedDate]);

    return (
        <div className="home-calendar__meetings">
            {list?.length > 0 &&
                list?.map((item, index) => {
                    if (index < 2) {
                        return <MeetingItem item={item} key={index} />;
                    } else if (index + 1 === list?.length) {
                        return (
                            <div
                                className="home-calendar__meeting__duration more-info"
                                key={index}
                                onClick={() => navigate("/calendar")}
                            >
                                {t("common:home.seeAll")}
                            </div>
                        );
                    }

                    return;
                })}
        </div>
    );
};

export default MeetingList;
