const Footer = ({ isChatVisible, mode, onEndCall }) => {
    return (
        mode === "meeting" && (
            <footer className={`footer ${isChatVisible ? "" : "active"}`}>
                <div className="footer__content">
                    <div className="footer__content-video-control">
                        <div className="footer__content-video-control-btn button-share active">
                            <i className="bi bi-upload"></i>
                        </div>
                        <div className="footer__content-video-control-btn button-mic active">
                            <i className="bi bi-mic"></i>
                        </div>
                        <div className="footer__content-video-control-btn button-call active" onClick={onEndCall}>
                            <i className="bi bi-telephone"></i>
                        </div>
                        <div className="footer__content-video-control-btn button-cam active">
                            <i className="bi bi-camera-video"></i>
                        </div>
                        <div className="footer__content-video-control-btn button-full active">
                            <i className="bi bi-arrows-fullscreen"></i>
                        </div>
                    </div>
                </div>
            </footer>
        )
    );
};

export default Footer;
