import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const paymentManagement = createApi({
    reducerPath: "paymentManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            const jwtToken = localStorage.getItem("userToken");

            if (jwtToken) {
                headers.set("authorization", `Bearer ${jwtToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        createStripeSession: builder.mutation({
            query: (body) => ({
                url: "users/create-stripe-session-subscription",
                method: "POST",
                body: body,
            }),
        }),
    }),
});

export const { useCreateStripeSessionMutation } = paymentManagement;
