import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CalendarClock } from "./componets/CalendarClock";
import { useUserToken } from "../../../../hooks/core/UserToken";
import { resetUser } from "../../../../slices/User";

const Header = ({ mode, title = "Virtual meeting" }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [language, setLanguage] = useState("en");
    const [isCopied, setIsCopied] = useState(false);
    const userInfo = useSelector((state) => state.user);

    const [, i18n] = useTranslation();

    const [userToken] = useUserToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCopyLink = () => {
        try {
            const link = window?.location?.href;
            setIsCopied(true);
            navigator.clipboard.writeText(link);
            setTimeout(() => {
                setIsCopied(false);
            }, 1500);
        } catch (error) {
            console.log(error);
        }
    };

    const hanldeAction = (type) => {
        switch (type) {
            case "edit-profile":
                return navigate("/profile");
            case "sign-out":
                dispatch(resetUser());
                sessionStorage.removeItem("workspaceId");
                userToken.removeToken();
                navigate("/sign-up/login");
                return;
            case "en":
            case "de":
                sessionStorage.setItem("language", type);
                i18n.changeLanguage(type);
                setLanguage(type);
                return;
            default:
                return;
        }
    };

    useEffect(() => {
        const selectedLanguage = sessionStorage.getItem("language");
        if (selectedLanguage) {
            setLanguage(selectedLanguage);
        }
    }, []);

    return (
        <header className="header">
            <div className="header__content">
                <div className="header__content-main">
                    <div className="header__content-title">
                        <div className="header__content-title-main">
                            <span>{userInfo?.workspace?.name || "QMeet"}</span>
                            {title ? ` - ${title}` : ""}
                        </div>
                        {mode === "meeting" && (
                            <div className="btn" onClick={handleCopyLink}>
                                {isCopied ? (
                                    <i className="bi bi-check2 text-success"></i>
                                ) : (
                                    <i className="bi bi-link-45deg"></i>
                                )}
                                <span>Share Link</span>
                            </div>
                        )}
                    </div>
                    <div className={`header__content-line ${mode}`}></div>
                </div>
                <div className="header__content-user">
                    <CalendarClock />
                    <div className="header__content-user-profile">
                        <div
                            className={`participant-avatar ${userInfo?.user?.avatar ? "no-background" : ""}`}
                            onClick={() => setShowDropdown((prevState) => !prevState)}
                        >
                            {userInfo?.user?.avatar ? (
                                <img
                                    src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${userInfo?.user?.avatar}`}
                                    alt=""
                                />
                            ) : (
                                <i className="bi bi-person-fill"></i>
                            )}
                        </div>
                        <div className="side-menu__dropdown settings" style={{ height: showDropdown ? 3 * 45 + 5 : 0 }}>
                            <ul className="side-menu__list">
                                <li className="side-menu__item" onClick={() => hanldeAction("edit-profile")}>
                                    <span>Edit Profile</span>
                                </li>
                                <li className="side-menu__item" onClick={() => hanldeAction("sign-out")}>
                                    <span>Sign out</span>
                                </li>
                                <li className="side-menu__item">
                                    <div className="side-menu__item--languages">
                                        <div className="side-menu__item--language" onClick={() => hanldeAction("en")}>
                                            <div>EN</div>
                                            <div className={language === "en" ? "active" : ""}></div>
                                        </div>
                                        <div>|</div>
                                        <div className="side-menu__item--language" onClick={() => hanldeAction("de")}>
                                            <div>DE</div>
                                            <div className={language === "de" ? "active" : ""}></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
