import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { workspaceManagement } from "../../../../hooks/api/WorkspaceManagement";
import { setIsLoading } from "../../../../slices/Status";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";
import { Overlay } from "../../../../components/Overlay";

const InviteModal = forwardRef(({ labelConfirm, labelCancel, text, getUsersList }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [email, setEmail] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");

    const dispatch = useDispatch();

    const { useInviteUserMutation } = workspaceManagement;
    const [inviteUser] = useInviteUserMutation();

    const handleClose = () => {
        setEmail("");
        setIsVisible(false);
    };

    const validateForm = () => {
        let isValid = true;

        const workspaceId = sessionStorage.getItem("workspaceId");

        if (!workspaceId || !email || !email?.trim()) {
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            dispatch(setIsLoading(true));

            const isFormValid = validateForm();

            if (!isFormValid) {
                return;
            }

            const workspaceId = sessionStorage.getItem("workspaceId");

            const params = {
                body: {
                    users: email,
                },
                workspaceId,
            };

            await inviteUser(params).unwrap();

            dispatch(setIsLoading(false));

            handleClose();

            setNotificationMessage("Invitation sent.");
            setNotificationType("success");
            setShowNotification(true);

            getUsersList && getUsersList();
        } catch (error) {
            dispatch(setIsLoading(false));

            setIsVisible(false);

            setNotificationMessage("User is already in this workspace. Please, try with different email.");
            setShowNotification(true);
            setNotificationType("error");
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            show: () => {
                setIsVisible(true);
            },
            hide: () => handleClose(),
        }),
        [],
    );

    return (
        <>
            <Overlay isVisible={isVisible}>
                <div className={`confirm-modal ${isVisible ? "open" : ""}`}>
                    <div className="confirm__text mt-0 mb-3">{text || "Invite people"}</div>
                    <div className="confirm__description mt-0 mb-3">
                        {text || "Please enter the email address of the person you want to invite:"}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="settings__container__form-inputs ms-0 mb-4">
                            <div className="settings__container__form-input full-width">
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="confirm__btns">
                            <button type="button" onClick={handleClose}>
                                {labelCancel || "Cancel"}
                            </button>
                            <button type="submit" className="confirm">
                                {labelConfirm || "Send invitation"}
                            </button>
                        </div>
                    </form>
                </div>
            </Overlay>
            <div className="notification-list">
                <NotificationDialog
                    type={notificationType}
                    isVisible={showNotification}
                    message={notificationMessage}
                    onClose={() => {
                        setShowNotification(false);
                        setNotificationMessage("");
                        setNotificationType("");
                    }}
                />
            </div>
        </>
    );
});

export default InviteModal;
