import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const packageManagement = createApi({
    reducerPath: "packageManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            const jwtToken = localStorage.getItem("userToken");

            if (jwtToken) {
                headers.set("authorization", `Bearer ${jwtToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        packageList: builder.mutation({
            query: () => ({
                url: "package",
                method: "GET",
            }),
        }),
        viewPackage: builder.mutation({
            query: (packageId) => ({
                url: `package/${packageId}`,
                method: "GET",
            }),
        }),
        createPackage: builder.mutation({
            query: (body) => ({
                url: `package`,
                method: "POST",
                body: body,
            }),
        }),
        updatePackage: builder.mutation({
            query: ({ body, param }) => ({
                url: `package/${param}`,
                method: "PATCH",
                body: body,
            }),
        }),
        deletePackage: builder.mutation({
            query: (packageId) => ({
                url: `package/${packageId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    usePackageListMutation,
    useCreatePackageMutation,
    useViewPackageMutation,
    useUpdatePackageMutation,
    useDeletePackageMutation,
} = packageManagement;
