import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useUserToken } from "../../../../../../hooks/core/UserToken";
import { userManagement } from "../../../../../../hooks/api/UserManagement";
import { Overlay } from "../../../../../../components/Overlay";
import { PasswordInput } from "./components/PaswordInput";
import { setIsLoading } from "../../../../../../slices/Status";
import { NotificationDialog } from "../../../../../../dialogs/boxes/Notification";
import { YesNo } from "../../../../../../dialogs/boxes/YesNo";

const specialCharacters = ["!", "@", "#", "$", "%", "&", "*", ":", ";", ".", "?"];

const ChangePassword = ({ isVisible, title, labelCancel, labelConfirm, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [password, setPassword] = useState({
        current: "",
        new: "",
        newConfirmed: "",
    });
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState("");
    const [showYesNoModal, setShowYesNoModal] = useState(false);

    const [userToken] = useUserToken();

    const { useUpdateUserMutation, useCheckPasswordMutation } = userManagement;
    const [updateUser] = useUpdateUserMutation();
    const [checkPassword] = useCheckPasswordMutation();

    const handleCloseModal = () => {
        try {
            setPassword({ current: "", new: "", newConfirmed: "" });
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event.target;

            setPassword({ ...password, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const validatePassword = () => {
        if (password.new !== password.newConfirmed) {
            setShowNotification(true);
            setNotificationMessage("Please, ensure both passwords are identical.");
            setNotificationType("warning");

            return false;
        }
        if (password?.new < 8) {
            setShowNotification(true);
            setNotificationMessage("Passwords must be at least 8 characters long.");
            setNotificationType("warning");

            return false;
        }

        if (password?.new.includes(" ")) {
            setShowNotification(true);
            setNotificationMessage("Passwords should not contain any spaces.");
            setNotificationType("warning");

            return false;
        }

        const hasSpecialCharacters = specialCharacters?.find((character) => password?.new.includes(character));

        if (!hasSpecialCharacters) {
            setShowNotification(true);
            setNotificationMessage("Passwords should contain at least one special character.");
            setNotificationType("warning");

            return false;
        }

        return true;
    };

    const handleForgotPassword = () => {
        try {
            sessionStorage.removeItem("workspaceId");
            userToken.removeToken();
            setShowYesNoModal(false);
            navigate("/sign-up/forgotten-password");
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            dispatch(setIsLoading(true));

            const token = userToken.getDecoded();
            const userId = token.id;

            const response = await checkPassword(password.current).unwrap();

            if (response?.info?.statusCode === 200) {
                const isNewPasswordValid = validatePassword();

                if (isNewPasswordValid) {
                    await updateUser({
                        body: {
                            password: password.new,
                            passwordConfirm: password.newConfirmed,
                        },
                        param: userId,
                    }).unwrap();

                    setShowNotification(true);
                    setNotificationMessage("Your password has been changed successfully.");
                    setNotificationType("success");
                    handleCloseModal();
                }
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(setIsLoading(false));
            setShowNotification(true);
            setNotificationMessage("Something went wrong. Please check your inputs and try again.");
            setNotificationType("error");
        }
    };

    return (
        <>
            <Overlay isVisible={isVisible}>
                <div className={`confirm-modal ${isVisible ? "open" : ""}`}>
                    <div className="confirm__text mt-0 mb-3">{title || "Change password"}</div>
                    <form onSubmit={handleSubmit}>
                        <PasswordInput
                            name="current"
                            label="Current password"
                            value={password.current}
                            onChange={handleChange}
                            required={true}
                            className="mb-2"
                            forgotPassword={true}
                            onForgotPassword={() => setShowYesNoModal(true)}
                        />
                        <PasswordInput
                            name="new"
                            label="New password"
                            value={password.new}
                            onChange={handleChange}
                            required={true}
                            className="mb-2"
                        />
                        <PasswordInput
                            name="newConfirmed"
                            label="Confirm new password"
                            value={password.newConfirmed}
                            onChange={handleChange}
                            required={true}
                            className="mb-4"
                        />
                        <div className="confirm__btns">
                            <button type="button" onClick={handleCloseModal}>
                                {labelCancel || "Cancel"}
                            </button>
                            <button type="submit" className="confirm">
                                {labelConfirm || "Save"}
                            </button>
                        </div>
                    </form>
                </div>
            </Overlay>
            <div className="notification-list">
                <NotificationDialog
                    type={notificationType}
                    isVisible={showNotification}
                    message={notificationMessage}
                    onClose={() => {
                        setShowNotification(false);
                        setNotificationMessage("");
                        setNotificationType("");
                    }}
                />
            </div>
            <YesNo
                isVisible={showYesNoModal}
                text="This action will log you out. Do you want to continue?"
                maxWidth={500}
                onConfirm={handleForgotPassword}
                onCancel={() => setShowYesNoModal(false)}
            />
        </>
    );
};

export default ChangePassword;
