import { UserItem } from "../UserItem.js";

const UserList = ({ users, selectedUsers, onSelectUser }) => {
    return (
        users?.length > 0 &&
        users?.map((user, index) => {
            return (
                <UserItem
                    key={index}
                    user={user}
                    selectedUsers={selectedUsers}
                    onSelectUser={() => onSelectUser(user)}
                />
            );
        })
    );
};

export default UserList;
