import { useTranslation } from "react-i18next";

const TypingIndicator = ({ isTyping = true, user }) => {
    const { t } = useTranslation("common");

    return (
        isTyping && (
            <div className="aside__content-list-typing active">
                <span>{user.firstName}</span>
                <div className="message-typing">{t("common:typing")}</div>
            </div>
        )
    );
};

export default TypingIndicator;
