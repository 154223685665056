import { createContext, useEffect, useState } from "react";
import { socket as socketMain } from "../hooks/core/SocketService/socket";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const [socket, setSocket] = useState();

    useEffect(() => {
        setSocket(socketMain);
    }, []);

    return <AppContext.Provider value={{ socket: socket }}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
