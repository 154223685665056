const Message = ({ message, samePrevUser, sameNextUser, messageText }) => {
    const { firstName, lastName, time } = message;

    return (
        <li className={`aside__content-list-chat ${sameNextUser ? "mb-2" : ""}`}>
            <div className="participant-avatar" style={{ visibility: samePrevUser ? "hidden" : "visible" }}>
                {/* <!-- <i className="bi bi-person-fill"></i> --> */}
                <div className="participant-avatar__avatar-initials">
                    <span>{firstName?.length > 0 && firstName[0]}</span>
                    <span>{lastName?.length > 0 && lastName[0]}</span>
                </div>
                {/* <!-- <img src="assets/img/avatar-2.jpg" alt="" /> --> */}
            </div>
            <div className="aside__content-list-message">
                <div className="aside__content-list-msg">
                    <div className="aside__content-list-chat-participant">
                        {firstName} {lastName}
                    </div>
                    <div className="aside__content-list-chat-text">
                        <div>{messageText}</div>
                    </div>
                </div>
            </div>
            <div className="aside__content-list-timestamp">
                {time}
                {/* <span>AM</span> */}
            </div>
        </li>
    );
};

export default Message;
