import { useEffect, useState } from "react";

const CalendarClock = () => {
    const [time, setTime] = useState(null);
    const [date, setDate] = useState(null);

    const handleTime = () => {
        const date = new Date();

        const dateArray = date?.toString()?.split(" ");

        if (dateArray?.length > 0) {
            let day = dateArray[0];
            let month = dateArray[1];
            let dayNumber = dateArray[2];
            let time = dateArray[4];
            time = time?.slice(0, 5);

            setTime(time);
            setDate(`${day}, ${month} ${dayNumber}`);
        }
    };

    useEffect(() => {
        handleTime();
        const interval = setInterval(() => {
            handleTime();
        }, 3 * 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="header__content-timing">
            <span className="me-3">{time}</span>
            <span>{date}</span>
        </div>
    );
};

export default CalendarClock;
