import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import "isotope-layout/dist/isotope.pkgd.min";
import "jquery-bridget";
import "isotope-layout/dist/isotope.pkgd.min";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Peer } from "peerjs";
import { PreLoader } from "../../../../components/PreLoader";
import { ParticipantView } from "./components/ParticipantView";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useUserToken } from "../../../../hooks/core/UserToken";
import { NotificationDialog } from "../../../../dialogs/boxes/Notification";

var $ = require("jquery");
var jQueryBridget = require("jquery-bridget");
var Isotope = require("isotope-layout");
jQueryBridget("isotope", Isotope, $);

const data = [
    { firstName: "Dianne", lastName: "Russell" },
    { firstName: "Guy", lastName: "Hawkins" },
    { firstName: "Kathryn", lastName: "Murphy" },
    { firstName: "Joshua", lastName: "Abraham" },
    { firstName: "Peter", lastName: "Weller" },
    { firstName: "Dianne", lastName: "Russell" },
];

const constraints = {
    video: {
        frameRate: 15,
        aspectRatio: 16 / 9,
    },
    audio: false,
};

const ConferenceVideoPanel = forwardRef(({ isChatVisible }, ref) => {
    const navigate = useNavigate();
    const { meetingId: meetingIdFromURL } = useParams();
    const [userToken] = useUserToken();
    const token = userToken.getDecoded();
    const { socket } = useContext(AppContext);

    // isotope
    const [pinnedParticipant, setPinnedParticipant] = useState("none");
    const videoGrid = useRef();

    // video call
    const [participantList, setParticipantList] = useState([]);
    const [streams, setStreams] = useState({});
    const [notifications, setNotifications] = useState([]);

    const myPeer = useRef();
    const [peers, setPeers] = useState({});
    const [myPeerId, setMyPeerId] = useState(null);

    const isotopeInit = () => {
        videoGrid.current = $(".main__container-grid").isotope({
            itemSelector: ".main__container-video",
            masonry: {
                columnWidth: ".grid-sizer",
                gutter: ".gutter-sizer",
            },
            getSortData: {
                number: ".number parseInt",
            },
            // percentPosition: true,
        });
    };
    const adjustWidth = () => {
        const $mainContainer = $(".main__container");
        const $videoGridContainer = $(".main__container-grid");
        const $videoItemsNotPinned = $(".main__container-video:not(.main__container-video.active)");
        const $gridItemSizer = $(".grid-sizer, .main__container-video:not(.main__container-video.active)");

        const mainHeight = $mainContainer.outerHeight();
        const mainWidth = $mainContainer.outerWidth();

        let currentGridHeight, currentItemWidth, currentItemWidthPercent, newItemWidth;

        const startTime = performance.now();
        let loopIterations = 0;
        const maxIterations = 100;
        const percent = 0.1;

        $videoItemsNotPinned.css({ width: "100%" });

        while (loopIterations < maxIterations) {
            videoGrid.current = $videoGridContainer.isotope({});

            currentGridHeight = $videoGridContainer.outerHeight();
            currentItemWidth = $videoItemsNotPinned.outerWidth();

            currentItemWidthPercent = (currentItemWidth / mainWidth) * 100;
            newItemWidth = Math.floor(
                currentGridHeight >= mainHeight ? currentItemWidthPercent - percent : currentItemWidthPercent + percent,
            );

            $gridItemSizer.css({ width: newItemWidth + "%" });

            loopIterations++;

            if (currentGridHeight <= mainHeight) {
                const endTime = performance.now();
                const elapsedTime = endTime - startTime;

                break;
            }
        }
    };

    $.fn.resized = function (callback, timeout) {
        $(this).resize(function () {
            const $this = $(this);

            if ($this.data("resizeTimeout")) {
                clearTimeout($this.data("resizeTimeout"));
            }
            $this.data("resizeTimeout", setTimeout(callback, timeout));
        });
    };

    const handlePinParticipant = (id) => {
        const newValue = id === pinnedParticipant ? null : id;
        setPinnedParticipant(newValue);
    };

    const handlePinAnimation = () => {
        const animationInterval = parseFloat($(".main__container-video").css("animation-duration")) * 1000 + 10;
        const $videoItems = $(".main__container-video");

        setTimeout(function () {
            $videoItems.removeClass("inactive");
            adjustWidth();
        }, animationInterval);

        videoGrid.current.isotope("updateSortData").isotope();
        videoGrid.current = $(".main__container-grid").isotope({
            sortBy: "number",
        });
    };

    const createPeerConnection = () => {
        try {
            myPeer.current = new Peer({
                debug: parseInt(process.env.REACT_APP_PEERJS_DEBUG),
                config: { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] },
            });

            myPeer.current.on("open", (id) => {
                setMyPeerId(id);
                socket.emit("join-room", meetingIdFromURL, id, token.id);
            });

            myPeer.current.on("error", (err) => {
                console.error("PeerJS error:", err);
            });

            myPeer.current.on("connection", (connection) => {
                console.log("Connected to peer:", connection.peer);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddStream = (peerId, stream) => {
        if (peerId) {
            setStreams((prevState) => ({ ...prevState, [peerId]: stream }));
        }
    };

    // The call function is used to initiate a call to a remote peer
    const connectToNewUser = useCallback(
        (remotePeerId, stream) => {
            if (stream && remotePeerId) {
                const call = myPeer.current.call(remotePeerId, stream);

                call.on("stream", (remoteStream) => {
                    handleAddStream(remotePeerId, remoteStream);
                });

                call.on("close", () => {
                    console.log("close call");
                });

                setPeers((prevState) => ({ ...prevState, [remotePeerId]: call }));
            }
        },
        [peers, myPeer],
    );

    const closePeer = (peerId, peers) => {
        if (peers[peerId]) {
            peers[peerId].close();

            const updatePeers = { ...peers };
            delete updatePeers[peerId];
            setPeers(updatePeers);
        }
    };

    const closeAllLocalPeers = async (peers) => {
        let updatePeers = { ...peers };
        for (const peerId in updatePeers) {
            if (updatePeers[peerId]) {
                updatePeers[peerId].close();
                delete updatePeers[peerId];
            }
        }
        setPeers(updatePeers);
    };

    const closeAllLocalStreams = async (streams) => {
        for (const peerId in streams) {
            const stream = streams[peerId];
            const tracks = await stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    const closeRemoteStream = (peerId) => {
        const updateUsersStreams = { ...streams };
        delete updateUsersStreams[peerId];

        setStreams(updateUsersStreams);
    };

    const handleNotification = (user, type) => {
        try {
            let message = `${user?.user?.firstName} ${user?.user?.lastName}`;

            if (type === "add") {
                message = message + " joined the meeting.";
            } else if (type === "remove") {
                message = message + " has left the meeting.";
            }

            let id = user?.user?.id;

            setNotifications((prevState) => [...prevState, { message, id }]);
        } catch (error) {}
    };

    const handleCloseNotification = (id) => {
        setNotifications((prevState) => prevState?.filter((notification) => notification?.id != id));
    };

    useEffect(() => {
        if (videoGrid?.current) {
            handlePinAnimation();
        }
        // eslint-disable-next-line
    }, [pinnedParticipant]);

    useEffect(() => {
        if (participantList?.length > 0) {
            if (!videoGrid?.current) {
                isotopeInit();
            }

            const $item = $(".main__container-video.new-video-item");

            if (participantList?.length > 1) {
                videoGrid.current.append($item).isotope("appended", $item);
                $(".main__container-video").removeClass("new-video-item");
                videoGrid.current.isotope("reloadItems");
            }

            const gridItemNum = Math.max(participantList?.length, 4);

            setTimeout(() => {
                $(".main__container-video").removeClass("new-video-item inactive");
                adjustWidth();
            }, gridItemNum * 100);

            $(window).resized(adjustWidth, gridItemNum * 100);
        }
    }, [participantList]);

    useEffect(() => {
        if (videoGrid?.current) {
            let asideInterval = parseFloat($(".main").css("transition").split("width ")[1].split("s")[0]) * 1000;
            setTimeout(() => {
                adjustWidth();
            }, asideInterval);
        }
    }, [isChatVisible]);

    useEffect(() => {
        return () => {
            console.log("resize event off");
            // videoGrid.current.isotope("destroy");
            $(window).off("resize");
        };
    }, []);

    useEffect(() => {
        if (!myPeer?.current) {
            createPeerConnection();
        }
    }, []);

    // useEffect(() => {
    //     if (myPeerId) {
    //         navigator.mediaDevices
    //             .getUserMedia(constraints)
    //             .then((stream) => {
    //                 handleAddStream(myPeerId, stream);

    //                 // When another peer tries to call us
    //                 // we answer the incoming call
    //                 // and send our media stream to the other pee
    //                 myPeer.current.on("call", (call) => {
    //                     const peerId = call.peer;
    //                     setPeers((prevState) => ({ ...prevState, [peerId]: call }));

    //                     call.answer(stream);

    //                     call.on("stream", (remoteStream) => {
    //                         handleAddStream(peerId, remoteStream);
    //                     });
    //                 });

    //                 socket.on("new-user-connected", (peerId) => {
    //                     console.log("new-user-connected");
    //                     connectToNewUser(peerId, stream);
    //                 });
    //             })
    //             .catch((error) => console.log(error));
    //     }

    //     return () => {
    //         socket.off("new-user-connected");
    //     };
    // }, [myPeerId]);

    useEffect(() => {
        socket.on("get-users-in-room", (participants, meetingId) => {
            if (meetingId === meetingIdFromURL) {
                setParticipantList(participants);
            }
        });

        socket.on("show-notification", (user, type) => {
            handleNotification(user, type);
        });

        return () => {
            socket.off("get-users-in-room");
            socket.off("show-notification");
        };
    }, []);

    useEffect(() => {
        socket.on("user-disconnected", (peerId) => {
            // closePeer(peerId, peers);
            // closeRemoteStream(peerId);
        });

        socket.on("exit-room", (peerId) => {
            // closePeer(peerId, peers);
            // closeRemoteStream(peerId);
        });

        return () => {
            socket.off("user-disconnected");
            socket.off("exit-room");
        };
    }, [peers, streams]);

    useEffect(() => {
        const loadType = sessionStorage.getItem("loadType");

        if (loadType === "reload") {
            socket.emit("exit-room", myPeerId);
            window.location.href = `/`;
        }

        const handleReloadPage = (event) => {
            event.preventDefault();
            sessionStorage.setItem("loadType", "reload");
        };

        window.addEventListener("beforeunload", handleReloadPage);

        return () => window.removeEventListener("beforeunload", handleReloadPage);
    }, []);

    useImperativeHandle(ref, () => ({
        endCall: async () => {
            socket.emit("exit-room", myPeerId);
            // await closeAllLocalStreams(streams);
            // closeAllLocalPeers(peers);
            setStreams({});
            setMyPeerId(null);
            myPeer.current.disconnect();
            navigate("/");
        },
    }));

    return (
        <>
            <main className={`main ${isChatVisible ? "" : "active"}`}>
                <div className="main__container">
                    <PreLoader />
                    <div className="main__container-grid">
                        <div className="grid-sizer"></div>
                        <div className="gutter-sizer"></div>
                        {participantList?.length > 0 &&
                            participantList?.map((user, index) => {
                                const userPeerId = user?.peerId;
                                const stream = userPeerId && streams[userPeerId];

                                return (
                                    <ParticipantView
                                        key={index}
                                        id={user.peerId}
                                        firstName={user.user.firstName}
                                        lastName={user.user.lastName}
                                        avatar={user.user?.avatar}
                                        pinnedParticipant={pinnedParticipant}
                                        isPinned={pinnedParticipant === userPeerId}
                                        stream={stream}
                                        onPinParticipant={handlePinParticipant}
                                        isMuted={userPeerId === myPeerId}
                                    />
                                );
                            })}
                    </div>
                </div>
            </main>
            {notifications?.length > 0 && (
                <div className="notification-list">
                    {notifications?.map((notification, index) => (
                        <NotificationDialog
                            key={index}
                            isVisible={notification?.id}
                            message={notification?.message}
                            onClose={handleCloseNotification}
                        />
                    ))}
                </div>
            )}
        </>
    );
});

export default ConferenceVideoPanel;
