const useDownloadImage = () => {
    const handleDownload = async (filename) => {
        try {
            const jwtToken = localStorage.getItem("userToken");

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/download/${filename}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

            if (!response.ok) {
                throw new Error("Oops! Something went wrong...");
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error("Failed to download file:", err);
        }
    };

    return [handleDownload];
};

export default useDownloadImage;
