import { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/AppContext";
import { MessageList } from "./components/MessageList";
import { TypingIndicator } from "./components/TypingIndicator";

const ChatContainer = ({ isChatVisible }) => {
    const { meetingId } = useParams();
    const { socket } = useContext(AppContext);
    const user = useSelector((state) => state.user);
    const { t } = useTranslation("common");
    const [content, setContent] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [typing, setTyping] = useState(false);
    const [isUserTyping, setIsUserTyping] = useState(false);
    const [typingUser, setTypingUser] = useState(null);

    let typingTimeout;

    const handleSendMessage = (event) => {
        event.preventDefault();
        const message = {
            roomId: meetingId,
            content: content,
            senderId: user,
        };
        socket.emit("send-message", message);
        setContent("");
    };

    const handleTyping = (event) => {
        setContent(event.target.value);
        if (!typing) {
            setTyping(true);
            socket.emit("typing", meetingId, user.user);
        }
        clearTimeout(typingTimeout);
        typingTimeout = setTimeout(() => {
            setTyping(false);
            socket.emit("stop-typing", meetingId);
        }, 2000);
    };

    useEffect(() => {
        socket.on("receive-message", (message) => {
            setMessageList((prevMessages) => [...prevMessages, message]);
        });
        socket.on("typing", (user) => {
            setTypingUser(user);
            setIsUserTyping(true);
        });
        socket.on("stop-typing", () => {
            setIsUserTyping(false);
        });
        return () => {
            socket.off("receive-message");
            socket.off("typing");
            socket.off("stop-typing");
        };
    }, [socket]);

    return (
        <aside className={`aside-right aside ${isChatVisible ? "active" : ""}`}>
            <div className="aside__content">
                <div className="aside__content--top">
                    <div className="aside__content-chat active">
                        <div className="aside__content-header">
                            <div className="aside__content-title">{t("common:inCallMessages")}</div>
                        </div>
                        <div className="aside__content-list aside__content-list--chat">
                            <MessageList messages={messageList} />
                            {isUserTyping && <TypingIndicator user={typingUser} />}
                        </div>
                    </div>
                </div>
                <div className="aside__content--bottom">
                    <div className="aside__content-chat-input">
                        <div className="aside__content-chat-input-attach">
                            <i className="bi bi-paperclip"></i>
                        </div>
                        <form className="aside__content-chat-input-type" onSubmit={handleSendMessage}>
                            <input
                                type="text"
                                placeholder={t("common:messagePlaceholder")}
                                id="chat-input"
                                value={content}
                                onChange={handleTyping}
                            />
                        </form>
                        <div className="aside__content-chat-input-send" onClick={handleSendMessage}>
                            <i className="bi bi-send"></i>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default ChatContainer;
