import { useEffect, useState } from "react";
import { MeetingList } from "./components/MeetingList";

const Day = ({ isToday, day, setCurrentDate, isSelected, meetingList, onSelectDay }) => {
    const [list, setList] = useState([]);

    const addOrdinalSuffix = (number) => {
        if (number < 1 || number > 31) {
            return "Out of range";
        }

        switch (number) {
            case 1:
                return "1st";
            case 2:
                return "2nd";
            case 3:
                return "3rd";
            default:
                return number + "th";
        }
    };

    const convertToTimestamp = (date, type) => {
        try {
            let timestamp = 0;
            let newDate = new Date(date);

            if (type === "end") {
                newDate.setDate(newDate.getDate() + 1);
            }

            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);

            timestamp = newDate.getTime();

            return timestamp;
        } catch (error) {
            console.log(error);

            return "";
        }
    };

    const handleFilterMeetings = (date) => {
        try {
            const endOfDay = convertToTimestamp(date, "end");
            const startOfDay = convertToTimestamp(date);
            let list = meetingList.filter((item) => item.startAt >= startOfDay && item.endAt < endOfDay);
            setList(list);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleFilterMeetings(day);
        // eslint-disable-next-line
    }, [meetingList]);

    useEffect(() => {
        if (isSelected) {
            onSelectDay(list);
        }
        // eslint-disable-next-line
    }, [isSelected, list]);

    return (
        <div
            className={`calendar__box__item ${isToday && isSelected ? "active" : ""} ${!isToday && isSelected ? "selected" : ""}`}
            onClick={() => {
                setCurrentDate(day);
                onSelectDay(list);
            }}
        >
            <div className="calendar__box__date">
                {isToday && <div className="calendar__box__today">Today</div>}
                <div className="calendar__box__day">{day.toLocaleDateString("default", { weekday: "short" })[0]}</div>
                <div className="calendar__box__day-number">{day && addOrdinalSuffix(day?.getDate())}</div>
            </div>
            <MeetingList list={list} />
            {list?.length > 0 && <div className="calendar__box__item__meetings-indicator">{list?.length}</div>}
        </div>
    );
};

export default Day;
