import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DefaultLayout } from "../../../../layouts/Default";
import { UploadImage } from "../../../Settings/components/UploadImage";
import { userManagement } from "../../../../hooks/api/UserManagement";
import { setIsLoading, setRefresh } from "../../../../slices/Status";
import { ChangePassword } from "./modals/ChangePassword/index";

const Profile = () => {
    const { t } = useTranslation("common");

    const { userId: userIdFromURL } = useParams();
    const mode = "profile";
    const title = userIdFromURL ? t("common:profile.editUser") : t("common:profile.profile");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user);

    const { useUpdateUserMutation, useViewUserMutation } = userManagement;
    const [updateUser] = useUpdateUserMutation();
    const [viewUser] = useViewUserMutation();

    const [user, setUser] = useState({
        avatar: "",
        firstName: "",
        lastName: "",
        email: "",
        position: "",
        password: "",
        workspaceName: "",
        phone: "",
        address: "",
        isActive: "",
    });
    const [isVisible, setIsVisible] = useState(false);

    const handleAvatar = (file) => {
        try {
            setUser({ ...user, avatar: file });
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event) => {
        try {
            const { name, value } = event.target;

            setUser({ ...user, [name]: value });
        } catch (error) {
            console.log(error);
        }
    };

    const getUserDetails = async (id) => {
        try {
            dispatch(setIsLoading(true));
            const response = await viewUser(id).unwrap();

            const newUser = response?.info?.user;

            setUser({
                ...user,
                avatar: newUser?.avatar || "",
                firstName: newUser?.firstName || "",
                lastName: newUser?.lastName || "",
                email: newUser?.email || "",
                position: newUser?.position || "",
                password: newUser?.password || "",
                // workspaceName:,
                phone: newUser?.phone || "",
                address: newUser?.address || "",
                isActive: !!newUser?.isActive,
            });
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();

            dispatch(setIsLoading(true));

            const formData = new FormData();

            let saveParams = {};

            if (userIdFromURL) {
                formData.append("avatar", user.avatar);
                formData.append("position", user.position);
                formData.append("phone", user.phone);
                formData.append("isActive", user.isActive);
                saveParams = { param: userIdFromURL, body: formData };
            } else {
                formData.append("avatar", user.avatar);
                formData.append("firstName", user.firstName);
                formData.append("lastName", user.lastName);
                formData.append("position", user.position);
                formData.append("phone", user.phone);
                formData.append("address", user.address);
                formData.append("isActive", user.isActive);

                saveParams = { param: userInfo?.user?.id, body: formData };
            }

            await updateUser(saveParams).unwrap();
            getUserDetails(saveParams.param);

            if (userIdFromURL) {
                navigate(-1);
            }

            dispatch(setIsLoading(false));
            dispatch(setRefresh(true));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (!userIdFromURL) {
            const newUser = {
                avatar: userInfo?.user?.avatar || "",
                firstName: userInfo?.user?.firstName || "",
                lastName: userInfo?.user?.lastName || "",
                email: userInfo?.user?.email || "",
                position: userInfo?.user?.position || "",
                password: userInfo?.user?.password || "",
                workspaceName: userInfo?.workspace?.name || "",
                phone: userInfo?.user?.phone || "",
                address: userInfo?.user?.address || "",
                isActive: userInfo?.user?.isActive || false,
            };
            setUser(newUser);
        } else {
            getUserDetails(userIdFromURL);
        }

        // eslint-disable-next-line
    }, [userInfo]);

    return (
        <>
            <DefaultLayout mode={mode} title={title}>
                <main className="main settings details">
                    <div className="settings__container">
                        <div className="settings__title">{title}</div>
                        <div className="settings__btns-container">
                            <button className="settings-btn" onClick={() => navigate(-1)}>
                                {t("common:cancel")}
                            </button>
                            <button className="settings-btn save" onClick={handleSubmit}>
                                {t("common:save")}
                            </button>
                        </div>
                        <div className="settings__container__form-container">
                            <form className="settings__container__form">
                                <UploadImage
                                    width={300}
                                    height={300}
                                    defaultImage={user?.avatar}
                                    onChangeImage={handleAvatar}
                                    options={{ deleteMedia: true }}
                                />
                                <div className="settings__container__form-inputs">
                                    <div className="settings__container__form-input">
                                        <label htmlFor="firstName">{t("common:firstName")}*</label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            value={user.firstName}
                                            onChange={handleChange}
                                            readOnly={!!userIdFromURL}
                                            required
                                        />
                                    </div>
                                    {!userIdFromURL && (
                                        <div className="settings__container__form-input">
                                            <label htmlFor="workspaceName">{t("common:company")}</label>
                                            <input
                                                type="text"
                                                id="workspaceName"
                                                name="workspaceName"
                                                value={user?.workspaceName}
                                                readOnly
                                            />
                                        </div>
                                    )}
                                    <div className="settings__container__form-input">
                                        <label htmlFor="lastName">{t("common:lastName")}*</label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={user?.lastName}
                                            onChange={handleChange}
                                            readOnly={!!userIdFromURL}
                                            required
                                        />
                                    </div>
                                    <div className="settings__container__form-input">
                                        <label htmlFor="position">{t("common:position")}*</label>
                                        <input
                                            type="text"
                                            id="position"
                                            name="position"
                                            value={user?.position}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="settings__container__form-input">
                                        <label htmlFor="email">{t("common:email")}</label>
                                        <div className="form-input__container">
                                            <i className="bi bi-envelope"></i>
                                            <input type="email" id="email" name="email" value={user?.email} readOnly />
                                        </div>
                                    </div>
                                    <div className="settings__container__form-input">
                                        <label htmlFor="phone">{t("common:phone")}</label>
                                        <div className="form-input__container">
                                            <i className="bi bi-telephone"></i>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                value={user?.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {!userIdFromURL && (
                                        <div className="settings__container__form-input justify-content-end">
                                            <button type="button" className="btn" onClick={() => setIsVisible(true)}>
                                                Change password
                                            </button>
                                        </div>
                                    )}
                                    {!userIdFromURL && (
                                        <div className="settings__container__form-input">
                                            <label htmlFor="address">{t("common:address")}</label>
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                value={user?.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                                    {userIdFromURL && (
                                        <div className="settings__container__form-input">
                                            <label>{t("common:status")}</label>
                                            <div className="d-flex align-items-center mt-2">
                                                <div className="d-flex me-3">
                                                    <input
                                                        type="radio"
                                                        id="active"
                                                        name="status"
                                                        className="user-modal__checkbox"
                                                        checked={user?.isActive}
                                                        onChange={() => setUser({ ...user, isActive: true })}
                                                    />
                                                    <label htmlFor="active" className="radio-label"></label>
                                                    <label htmlFor="active" className="user-modal__label--checkbox">
                                                        {t("common:active")}
                                                    </label>
                                                </div>
                                                <div className="d-flex">
                                                    <input
                                                        type="radio"
                                                        id="deactive"
                                                        name="status"
                                                        className="user-modal__checkbox"
                                                        checked={!user?.isActive}
                                                        onChange={() => setUser({ ...user, isActive: false })}
                                                    />
                                                    <label htmlFor="deactive" className="radio-label"></label>
                                                    <label htmlFor="deactive" className="user-modal__label--checkbox">
                                                        {t("common:deactive")}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
                <div className="welcome__container-description">
                    {t("common:founderText")} <span>{t("common:founderName")}</span>
                </div>
            </DefaultLayout>
            <ChangePassword isVisible={isVisible} onClose={() => setIsVisible(false)} />
        </>
    );
};

export default Profile;
