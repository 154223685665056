import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const meetingManagement = createApi({
    reducerPath: "meetingManagement",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
        prepareHeaders: (headers) => {
            const jwtToken = localStorage.getItem("userToken");

            if (jwtToken) {
                headers.set("authorization", `Bearer ${jwtToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        meetingList: builder.mutation({
            query: () => ({
                url: "meeting",
                method: "GET",
            }),
        }),
        viewMeeting: builder.mutation({
            query: (meetingId) => ({
                url: `meeting/${meetingId}`,
                method: "GET",
            }),
        }),
        createMeeting: builder.mutation({
            query: (body) => ({
                url: `meeting`,
                method: "POST",
                body: body,
            }),
        }),
        updateMeeting: builder.mutation({
            query: ({ body, param }) => ({
                url: `meeting/${param}`,
                method: "PATCH",
                body: body,
            }),
        }),
        deleteMeeting: builder.mutation({
            query: (meetingId) => ({
                url: `meeting/${meetingId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useMeetingListMutation,
    useViewMeetingMutation,
    useCreateMeetingMutation,
    useUpdateMeetingMutation,
    useDeleteMeetingMutation,
} = meetingManagement;
