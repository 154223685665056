import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { DefaultLayout } from "../../layouts/Default";
import { MeetingList } from "./components/MeetingList";
import { MeetingDropdown } from "./components/MeetingDropdown";
import { Ok } from "../../dialogs/boxes/Ok";
import loginImage from "../../assets/images/login-image.png";

const Home = () => {
    const mode = "home";

    const navigate = useNavigate();

    const { t } = useTranslation("common");

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isAsideVisible, setIsAsideVisible] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [meetingLink, setMeetingLink] = useState("");
    const [meetingCode, setMeetingCode] = useState("");

    const handleResize = useCallback(() => {
        const width = window.innerWidth;
        if (width > 1200 && isAsideVisible) {
            setIsAsideVisible(false);
        }
    }, [isAsideVisible]);

    const handleJoinMeet = (event) => {
        try {
            event.preventDefault();

            if (!meetingCode) {
                return;
            }

            if (meetingCode?.includes(`/premeeting/`) || meetingCode?.includes(`/meeting/`)) {
                window.location.href = meetingCode;
            } else {
                navigate(`/premeeting/${meetingCode}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };

        // eslint-disable-next-line
    }, [isAsideVisible]);

    useEffect(() => {
        const loadType = sessionStorage.getItem("loadType");

        if (loadType === "reload") {
            sessionStorage.setItem("loadType", "first-load");
        }
    }, []);

    return (
        <DefaultLayout mode={mode}>
            <main className="main welcome home">
                <div className="main__container-home">
                    <div className="main__container-img">
                        <img src={loginImage} alt="" />
                    </div>
                    <div
                        className="main__container-description"
                        dangerouslySetInnerHTML={{ __html: t("common:home.createMeet") }}
                    ></div>
                    <form className="main__container__form" onSubmit={handleJoinMeet}>
                        <div className="main__container__form-left">
                            <div className="position-relative home-dropdown__container">
                                <button
                                    type="button"
                                    className="home-btn"
                                    onClick={() => setShowDropdown((prevState) => !prevState)}
                                >
                                    <i className="bi bi-plus-circle"></i> {t("common:home.newMeet")}
                                </button>
                                <MeetingDropdown
                                    isVisible={showDropdown}
                                    onCreateMeetForLater={(newLink) => {
                                        setMeetingLink(newLink);
                                        setIsModalVisible(true);
                                    }}
                                    onCloseDropdown={() => setShowDropdown(false)}
                                />
                            </div>
                            <div className="main__container-input">
                                <i className="bi bi-link-45deg"></i>
                                <input
                                    type="text"
                                    value={meetingCode}
                                    onChange={(event) => setMeetingCode(event.target.value)}
                                    placeholder={t("common:home.placeholder")}
                                />
                            </div>
                        </div>
                        <button type="submit" className="main__container__form__btn">
                            {t("common:join")}
                        </button>
                    </form>
                    <div
                        className="main__container-description"
                        dangerouslySetInnerHTML={{ __html: t("common:home:shareLink") }}
                    ></div>
                    <div
                        className="main__container-description text"
                        dangerouslySetInnerHTML={{ __html: t("common:home.description") }}
                    ></div>
                </div>
                <div className={`main__container-calendar ${isAsideVisible ? "calendar-open" : ""}`}>
                    <Calendar
                        value={selectedDate}
                        onChange={setSelectedDate}
                        prev2Label=""
                        next2Label=""
                        navigationLabel={({ date, label, locale, view }) => {
                            const labelArray = label?.split(" ");
                            const month = label?.length > 0 && labelArray[0];
                            const year = label?.length > 0 && labelArray[1];

                            return (
                                <>
                                    {month}
                                    <br />
                                    <span className="react-calendar-label-year">{year}</span>
                                </>
                            );
                        }}
                        locale={sessionStorage.getItem("language") || "en"}
                    />
                    <MeetingList selectedDate={selectedDate} />
                </div>
                <div className={`main__calendar__icon ${isAsideVisible ? "calendar-open" : ""}`}>
                    <i className="bi bi-clock" onClick={() => setIsAsideVisible(true)}></i>
                    <i className="bi bi-x-lg" onClick={() => setIsAsideVisible(false)}></i>
                </div>
            </main>
            <Ok
                isVisible={isModalVisible}
                maxWidth={570}
                title="Here's your joining info"
                description="Send this to people you want to meet with. Be sure to save it so you can use it later, too."
                onClose={() => setIsModalVisible(false)}
                link={meetingLink}
            />
        </DefaultLayout>
    );
};

export default Home;
