import { useEffect, useState } from "react";
import { MeetingDetails } from "./components/MeetingDetails";

const DayDetails = ({ onMeetClick, currentDate, list }) => {
    const [hours, setHours] = useState([]);

    const generateDayHours = () => {
        const hours = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const time = `${hour < 10 ? "0" + hour : hour}:${minute === 0 ? "00" : minute}`;
                hours.push(time);
            }
        }

        setHours(hours);
    };

    const addOrdinalSuffix = (number) => {
        if (number < 1 || number > 31) {
            return "Out of range";
        }

        switch (number) {
            case 1:
                return "1st";
            case 2:
                return "2nd";
            case 3:
                return "3rd";
            default:
                return number + "th";
        }
    };

    useEffect(() => {
        generateDayHours();
    }, []);

    return (
        <div className="calendar__box">
            <div className="calendar__box__title">
                {currentDate && (
                    <>
                        {addOrdinalSuffix(currentDate.getDate())},
                        <span> {currentDate.toLocaleDateString("default", { weekday: "long" })?.substring(0, 3)}</span>
                    </>
                )}
            </div>
            <div className="calendar__box__details">
                <div className="calendar__box__hours">
                    {hours?.map((hour, index) => {
                        return hour?.includes(":00") ? (
                            <div data-time={hour} key={index}>
                                {hour}
                            </div>
                        ) : (
                            <div data-time={hour} key={index}>
                                <span></span>
                            </div>
                        );
                    })}
                </div>
                <div className="calendar__box__meet-container">
                    {list?.length > 0 &&
                        list?.map((item, index) => {
                            let now = new Date().getTime();
                            const isDisabled = item.endAt < now && item.startAt < now;

                            return (
                                <MeetingDetails
                                    meetInfo={item}
                                    key={index}
                                    onMeetClick={onMeetClick}
                                    index={index}
                                    list={list}
                                    isDisabled={isDisabled}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default DayDetails;
