import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    id: null,
    email: "",
    firstName: "",
    lastName: "",
    package: "",
    workspaces: [],
    isActive: "",
    isVerified: "",
    emailToken: "",
    avatar: "",
};

const initialWorkspaceState = {
    _id: "",
    name: "",
    logo: "",
    users: [],
    paid: false,
    packageName: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: initialUserState,
        workspace: initialWorkspaceState,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = { ...state.user, ...action.payload.user };
            state.workspace = { ...state.workspace, ...action.payload.workspace };
        },
        setUserWorkspace: (state, action) => {
            state.workspace = { ...state.workspace, ...action.payload };
        },
        resetUser: (state) => {
            state.user = initialUserState;
            state.workspace = initialWorkspaceState;
        },
    },
});

export const { setUser, setUserWorkspace, resetUser } = userSlice.actions;

export default userSlice.reducer;
