import "./App.css";
import { Loader } from "./components/Loader";
import { SocketContext } from "./context/SocketContext";
import { DefaultRouter } from "./routes";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SERVER_ENDPOINT || "http://localhost:8000");

socket.on("connect", () => {
    console.log("connected to server");
});

socket.on("disconnect", () => {
    console.log("disconnected from server");
});

socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
});

function App() {
    return (
        <SocketContext.Provider value={socket}>
            <Loader />
            <DefaultRouter />
        </SocketContext.Provider>
    );
}

export default App;
