import { useEffect, useState } from "react";

const PreLoader = ({ timeout = 600 }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        let timer = setTimeout(() => {
            setIsVisible(false);
        }, timeout);

        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={`preloader-wrap ${isVisible ? "" : "fade-out"}`}>
            <div className="new-preloader">
                <div className="square">{/*<!-- <i className="bi bi-person-video"></i> -->*/}</div>
                <div className="square">{/*<!-- <i className="bi bi-person-video"></i> -->*/}</div>
                <div className="square">{/*<!-- <i className="bi bi-person-video"></i> -->*/}</div>
                <div className="square">{/*<!-- <i className="bi bi-person-video"></i> -->*/}</div>
            </div>
        </div>
    );
};

export default PreLoader;
