import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { Login } from "../pages/SignUp/Login";
import { ForgottenPassword } from "../pages/SignUp/ForgottenPassword";
import { Meeting } from "../pages/Meeting";
import { NotFound } from "../pages/404";
import { Forbidden } from "../pages/403";
import { Register } from "../pages/SignUp/Register";
import { Home } from "../pages/Home";
import { PreMeeting } from "../pages/Meeting";
import { Settings } from "../pages/Settings";
import { WorkspaceDetails } from "../pages/Settings/pages/WorkspaceDetails";
import { WorkspacePeople } from "../pages/Settings/pages/WorkspacePeople";
import { WorkspaceSubscription, WorkspaceSubscriptionDetails } from "../pages/Settings/pages/WorkspaceSubscription";
import { Profile } from "../pages/Home/pages/Profile";
import { Subscription } from "../pages/Subscription";
import { Calendar } from "../pages/Calendar";
import { Lobby } from "../pages/Lobby";
import { Activation } from "../pages/SignUp/Activation";
import { ResetPassword } from "../pages/SignUp/ResetPassword";
import { WorkspaceForm } from "../pages/Subscription/pages/WorkspaceForm";

import { useUserStatus } from "../hooks/core/UserStatus";
import { useUserToken } from "../hooks/core/UserToken";
import { workspaceManagement } from "../hooks/api/WorkspaceManagement";
import { userManagement } from "../hooks/api/UserManagement";
import { setUser } from "../slices/User";

const PublicRoute = () => {
    const isLoggedIn = useUserStatus();

    return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

const PrivateRoute = () => {
    const [userToken] = useUserToken();
    const token = userToken?.getDecoded();
    const isLoggedIn = useUserStatus();
    const { useViewUserMutation } = userManagement;
    const { useViewWorkspaceMutation } = workspaceManagement;
    const [viewUser] = useViewUserMutation();
    const [viewWorkspace] = useViewWorkspaceMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [hasPremission, setHasPremission] = useState(false);

    const supportedRoles = ["admin", "super admin"];

    const location = useLocation();
    const pathName = location.pathname;
    const isAdminPage = pathName?.includes("settings") || pathName?.includes("/profile/");
    const isMeetingPage = pathName?.includes("/meeting/") || pathName?.includes("/premeeting/");

    const getUserDetails = async () => {
        try {
            setIsLoading(true);

            const response = await viewUser(token.id).unwrap();

            let workspaces = response.info.user.workspaces;
            workspaces =
                workspaces.length > 0 &&
                workspaces.filter(
                    (workspace) => workspace.workspace || (!workspace.workspace && workspace.role === "super admin"),
                );

            let workspaceResponse;

            let role = null;

            if (workspaces?.length === 1) {
                const currentWorkspace = workspaces[0];

                if (currentWorkspace?.workspace) {
                    workspaceResponse = await viewWorkspace(currentWorkspace?.workspace).unwrap();
                    sessionStorage?.setItem("workspaceId", currentWorkspace?.workspace);
                }

                role = currentWorkspace.role;
            } else if (workspaces?.length > 1 && !role) {
                const wokrspaceIdStorage = sessionStorage?.getItem("workspaceId");

                let selectedWorkspace = workspaces?.find((workspace) => workspace?.workspace === wokrspaceIdStorage);

                if (wokrspaceIdStorage) {
                    workspaceResponse = await viewWorkspace(wokrspaceIdStorage).unwrap();

                    sessionStorage?.setItem("workspaceId", wokrspaceIdStorage);

                    role = selectedWorkspace.role;
                } else {
                    navigate("/lobby");
                }
            }

            const hasPremission = (isAdminPage && supportedRoles?.includes(role)) || (!isAdminPage && isLoggedIn);

            const user = { ...response.info.user, role: role || "user" };

            const currentPath = location.pathname;
            const isPathAllowed =
                currentPath === "/subscription" ||
                currentPath?.includes("/subscription/workspace") ||
                currentPath?.includes("/profile");

            if (workspaceResponse) {
                await dispatch(setUser({ user, workspace: workspaceResponse?.info?.workspace }));

                const workspace = workspaceResponse?.info?.workspace;
                const isPaid = workspace.paid;
                const role = user.role;

                if (workspace?._id) {
                    if (!isPaid && role !== "super admin") {
                        const redirectPath = role === "user" && isPathAllowed ? currentPath : "/subscription";

                        navigate(redirectPath);
                    }
                }
            } else {
                await dispatch(setUser({ user }));
            }

            setHasPremission(hasPremission);

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getUserDetails();
        // eslint-disable-next-line
    }, []);

    return isMeetingPage ? (
        <Outlet />
    ) : !isLoggedIn ? (
        <Navigate to="/sign-up/login" />
    ) : isLoading && isLoggedIn && !hasPremission ? (
        <div className={`loader ${isLoading ? "" : "inactive"}`}></div>
    ) : (
        <Outlet />
    );
};

const router = createBrowserRouter([
    { path: "/sign-up/activation", element: <Activation /> },
    { path: "/sign-up/activation/:emailToken", element: <Activation /> },
    {
        path: "",
        element: <PrivateRoute />,
        children: [
            { path: "/", element: <Home /> },
            { path: "/profile", element: <Profile /> },
            { path: "/profile/:userId", element: <Profile /> },
            { path: "/premeeting", element: <PreMeeting /> },
            { path: "/calendar", element: <Calendar /> },
            { path: "/subscription", element: <Subscription /> },
            { path: "/subscription/workspace/:packageId", element: <WorkspaceForm /> },
            { path: "/settings", element: <Settings /> },
            { path: "/settings/workspace-details", element: <WorkspaceDetails /> },
            { path: "/settings/workspace-details/:workspaceId", element: <WorkspaceDetails /> },
            { path: "/settings/workspace-people", element: <WorkspacePeople /> },
            { path: "/settings/workspace-people/:workspaceId", element: <WorkspacePeople /> },
            { path: "/settings/workspace-subscription", element: <WorkspaceSubscription /> },
            { path: "/settings/workspace-subscription-details", element: <WorkspaceSubscriptionDetails /> },
            { path: "/lobby", element: <Lobby /> },
            { path: "/meeting", element: <Meeting /> },
            { path: "/meeting/:meetingId", element: <Meeting /> },
            { path: "/premeeting", element: <PreMeeting /> },
            { path: "/premeeting/:meetingId", element: <PreMeeting /> },
        ],
    },
    {
        path: "",
        element: <PublicRoute />,
        children: [
            { path: "/sign-up/login/:email", element: <Login /> },
            { path: "/sign-up/register/:email", element: <Register /> },
            { path: "/sign-up/login", element: <Login /> },
            { path: "/sign-up/register", element: <Register /> },
            { path: "/sign-up/forgotten-password", element: <ForgottenPassword /> },
            { path: "/sign-up/reset-password/:userId", element: <ResetPassword /> },
        ],
    },

    { path: "/403", element: <Forbidden /> },
    { path: "/404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" /> },
]);

const DefaultRouter = () => {
    return <RouterProvider router={router} />;
};

export default DefaultRouter;
