import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DefaultLayout } from "../../layouts/Default";
import { Pagination } from "./components/Pagination";
import { Table } from "./components/Table";
import { InviteModal } from "./modals/InviteModal";
import { UploadImage } from "../Settings/components/UploadImage";
import { YesNo } from "../../dialogs/boxes/YesNo";

import { useUserRole } from "../../hooks/core/UserRole";
import { workspaceManagement } from "../../hooks/api/WorkspaceManagement";
import { userManagement } from "../../hooks/api/UserManagement";
import { setIsLoading, setRefresh } from "../../slices/Status";

import { Overlay } from "../../components/Overlay";

const Settings = () => {
    const title = "Workspace";

    const emailModalRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.user);

    const currentLogo = userInfo?.workspace?.logo;

    const handleEditUser = (userId) => {
        const isAdmin = userInfo.user.id === userId;
        const redirectPath = isAdmin ? "/profile" : `/profile/${userId}`;
        navigate(redirectPath);
    };

    const columnDefsAdmin = [
        {
            headerName: "Employee",
            cellRender: (props) => {
                return (
                    <>
                        <div className={`participant-avatar ${props?.avatar ? "no-background" : ""}`}>
                            <i className="bi bi-person-fill"></i>
                            {props?.avatar && (
                                <img
                                    src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${props?.avatar}`}
                                    alt="avatar"
                                />
                            )}
                        </div>
                        <div>
                            <div className="settings__table-body__name">
                                {props?.firstName} {props?.lastName} {props?.id === userInfo?.user?.id ? "(me)" : ""}
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            headerName: "Position",
            field: "position",
        },
        { headerName: "Status", cellRender: (props) => (props?.isActive ? "Active" : "Deactivated") },
        {
            type: "edit",
            onClick: (props) => {
                const id = props.id;
                handleEditUser(id);
            },
        },
    ];

    const columnDefsSuperAdmin = [
        {
            type: "view-dark",
            width: 50,
            headerIcon: "",
            onClick: (props) => {
                navigate(`/settings/workspace-details/${props._id}`);
            },
        },
        {
            type: "checkbox",
            width: 50,
        },
        {
            headerName: "Logo",
            cellRender: (props) => {
                return (
                    props?.logo && (
                        <div className="settings__table-logo">
                            {props?.logo && (
                                <img
                                    src={`${process.env.REACT_APP_SERVER_ENDPOINT}/assets/${props?.logo}`}
                                    alt="Company logo"
                                />
                            )}
                        </div>
                    )
                );
            },
        },
        { headerName: "Name", field: "name" },
        { headerName: "City", field: "city" },
        { headerName: "Email", field: "workEmail" },
        { headerName: "Payment status", cellRender: (props) => (props?.paid ? "Paid" : "Unpaid") },
    ];

    // admin or super-admin
    const [mode, setMode] = useState("");
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const [modalType, setModalType] = useState(null);
    const [logo, setLogo] = useState(userInfo?.workspace?.logo || "");
    const [workspaceName, setWorkspaceName] = useState(userInfo?.workspace?.name || "");
    const [selectedIds, setSelectedIds] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const { useUpdateWorkspaceMutation, useDeleteWorkspaceMutation, useWorkspaceListPaginationMutation } =
        workspaceManagement;
    const [updateWorkspace] = useUpdateWorkspaceMutation();
    const [deleteWorkspace] = useDeleteWorkspaceMutation();
    const [workspaceListPagination] = useWorkspaceListPaginationMutation();
    const { useFilterUserListMutation } = userManagement;
    const [filterUserList] = useFilterUserListMutation();

    const role = useUserRole();

    const handleSelectWorkspace = useCallback(
        (workspace) => {
            try {
                const currentWorkspace = selectedIds?.find((selectedId) => selectedId === workspace?._id);

                if (currentWorkspace) {
                    let updateIds = selectedIds?.filter((selectedId) => selectedId !== workspace?._id);
                    setSelectedIds(updateIds);
                } else {
                    setSelectedIds([...selectedIds, workspace?._id]);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [selectedIds],
    );

    const handleDelete = async () => {
        try {
            setIsVisible(false);
            dispatch(setIsLoading(true));

            if (selectedIds?.length > 0) {
                for (let i = 0; i < selectedIds.length; i++) {
                    const workspaceId = selectedIds[i];

                    if (!workspaceId) {
                        return;
                    }
                    await deleteWorkspace(workspaceId).unwrap();
                }

                getWorkspaceList(currentPage, countPerPage);
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            dispatch(setIsLoading(true));

            const formData = new FormData();

            let saveParams = { param: sessionStorage.getItem("workspaceId"), body: {} };

            if (modalType === "logo") {
                formData.append("logo", logo);
                saveParams.body = formData;
            } else {
                formData.append("name", workspaceName);
                saveParams.body = formData;
            }

            await updateWorkspace(saveParams)?.unwrap();

            setModalType(null);

            dispatch(setIsLoading(false));
            dispatch(setRefresh(true));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const handleModalType = (type) => {
        setModalType(type);
    };

    const handleCloseModal = () => {
        setModalType(null);
    };

    const handleInviteModal = () => {
        if (emailModalRef?.current) {
            emailModalRef?.current?.show();
        }
    };

    const handlePageChange = (page, limit) => {
        const numberOfPages = Math.ceil(totalRecords / limit);
        if (!page || page > numberOfPages) {
            return;
        }
        setCurrentPage(page);
    };

    const getWorkspaceList = async (page, limit) => {
        try {
            dispatch(setIsLoading(true));
            const response = await workspaceListPagination({ page, limit })?.unwrap();

            setRowData(response?.records);
            setColDefs(columnDefsSuperAdmin);
            setTotalRecords(response?.totalRecords);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    const getWorkspaceDetails = async (page = 1, limit = 10) => {
        try {
            dispatch(setIsLoading(true));

            const workspaceId = sessionStorage.getItem("workspaceId") || userInfo?.workspace?._id;
            if (!workspaceId) {
                return;
            }

            const response = await filterUserList({ workspaceId: workspaceId, page, limit }).unwrap();

            const usersList = response?.records;
            setRowData(usersList);
            setColDefs(columnDefsAdmin);
            setTotalRecords(response.totalRecords);

            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(setIsLoading(false));
        }
    };

    useEffect(() => {
        if (role === "super admin") {
            setMode("super-admin");
        } else if (role === "admin") {
            setMode("admin");
        }
        // eslint-disable-next-line
    }, [role]);

    useEffect(() => {
        if (mode === "super-admin") {
            getWorkspaceList(currentPage, countPerPage);
        } else if (mode === "admin") {
            getWorkspaceDetails(currentPage, countPerPage);
        }
        // eslint-disable-next-line
    }, [mode, currentPage]);

    useEffect(() => {
        setLogo(currentLogo);
    }, [currentLogo]);

    return (
        <>
            <DefaultLayout mode={mode} title={title}>
                <main className="main settings">
                    <div className="settings__container">
                        {mode === "admin" ? (
                            <>
                                <div className="settings__title">{userInfo?.workspace?.name}</div>
                                <div className="settings__btns-container">
                                    <button className="settings-btn" onClick={() => handleModalType("name")}>
                                        Edit name
                                    </button>
                                    <button className="settings-btn" onClick={() => handleModalType("logo")}>
                                        Edit logo
                                    </button>
                                    <button className="settings-btn" onClick={() => handleInviteModal()}>
                                        <i className="bi bi-plus"></i> Add people
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="settings__btns-title--super">Workspaces</div>
                                <div className="settings__btns-container--super">
                                    <div className="settings__super-square"></div>
                                    <button
                                        className="settings-super-btn"
                                        onClick={() => navigate("/settings/workspace-details")}
                                        data-button-text="+ Add"
                                    >
                                        + Add
                                    </button>
                                    <button
                                        className="settings-super-btn"
                                        data-button-text="Delete"
                                        disabled={selectedIds?.length <= 0 ? true : false}
                                        onClick={() => setIsVisible(true)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </>
                        )}
                        <div className="settings__subtitle">{mode === "admin" ? "Employees" : "Organizations"}</div>
                        <Table
                            mode={mode}
                            colDefs={colDefs}
                            rowData={rowData}
                            onCheckboxRowSelect={handleSelectWorkspace}
                        />
                        <Pagination
                            onNextPage={() => handlePageChange(currentPage + 1, countPerPage)}
                            onPreviousPage={() => handlePageChange(currentPage - 1, countPerPage)}
                            currentPage={currentPage}
                            countPerPage={countPerPage}
                            totalRecords={totalRecords}
                            numberOfPages={Math.ceil(totalRecords / countPerPage)}
                        />
                    </div>
                </main>
                <div className="welcome__container-description">
                    Powered by <span>QMind Solutions</span>
                </div>
            </DefaultLayout>
            <InviteModal ref={emailModalRef} getUsersList={getWorkspaceDetails} />
            {modalType && (
                <Overlay>
                    <div className="confirm-modal open">
                        <div className="confirm__text mt-0 mb-3">Edit {modalType}</div>
                        <div className="confirm__description mt-0 mb-3"></div>
                        <form onSubmit={handleSubmit}>
                            <div
                                className={`settings__container__form-inputs ms-0 mb-4 ${modalType === "name" ? "" : "justify-content-center"}`}
                            >
                                {modalType === "name" ? (
                                    <div className="settings__container__form-input full-width">
                                        <input
                                            type="text"
                                            name="name"
                                            value={workspaceName}
                                            onChange={(event) => setWorkspaceName(event?.target.value)}
                                            required
                                        />
                                    </div>
                                ) : (
                                    <UploadImage
                                        defaultImage={logo}
                                        onChangeImage={(logo) => setLogo(logo)}
                                        options={{ deleteMedia: true }}
                                    />
                                )}
                            </div>
                            <div className="confirm__btns">
                                <button type="button" onClick={handleCloseModal}>
                                    Cancel
                                </button>
                                <button type="submit" className="confirm">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </Overlay>
            )}
            <YesNo
                isVisible={isVisible}
                text={`Are you sure you want to delete ${selectedIds?.length === 1 ? "this workspace" : "these workspaces"}?`}
                onCancel={() => setIsVisible(false)}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default Settings;
