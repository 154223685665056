import { useState } from "react";
import { Overlay } from "../../../components/Overlay";

const Ok = ({ isVisible, maxWidth = 400, description, title, padding = 30, link = "", onClose, disableClose }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyLink = () => {
        try {
            setIsCopied(true);
            navigator.clipboard.writeText(link || "q-meet-front.mvsoft.co.rs/meeting");
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Overlay isVisible={isVisible}>
            <div
                className={`confirm-modal ${isVisible ? "open" : ""} ok`}
                style={{ maxWidth: maxWidth, padding: padding }}
            >
                <div className="confirm__content-top">
                    <div className="confirm__content-top__title">{title}</div>
                    {!disableClose && (
                        <div className="confirm__content-top__icon" onClick={onClose}>
                            <i className="bi bi-x-lg"></i>
                        </div>
                    )}
                </div>
                <div className="confirm__description">{description}</div>
                {link && (
                    <div className="confirm__link-container">
                        <div className="confirm__link">{link}</div>
                        <div className="confirm__link-icon" onClick={handleCopyLink}>
                            {isCopied ? <i className="bi bi-check2 text-success"></i> : <i className="bi bi-copy"></i>}
                        </div>
                    </div>
                )}
            </div>
        </Overlay>
    );
};

export default Ok;
